import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Client, Devis } from '../../../models/models';

@Component({
  selector: 'app-devis',
  templateUrl: './devis.component.html',
  styleUrls: ['./devis.component.css']
})
export class DevisComponent implements OnInit {
  showLoading = false;
  error = false;
  errorMessage = '';
  showConfirmDelete = false;
  client: Client;
  devis : Devis;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore
  ) { 
    this.showLoading = true;
    route.params.subscribe(params => {
      if(!params.clientId){
        this.router.navigateByUrl('/clients');
      }else{
        this.afs.doc<Client>('Clients/' + params.clientId).valueChanges().subscribe(data => {
          this.client = data;
          this.devis = data.devis.find(x => x.id == params.devisId);
          this.showLoading = false;
        });
      }
    });
  }

  ngOnInit() {
  }

  editDevis(){
    this.router.navigateByUrl('/editDevis/' + this.client.id + '/' + this.devis.id);
  }

  closeDevis(){
    this.router.navigateByUrl('/client/' + this.client.id);
  }

  deleteDevis(){
    this.showLoading = true;
    var index = this.client.devis.findIndex(x => x.id == this.devis.id);
    this.client.devis.splice(index, 1);
    this.afs.doc<Client>('Clients/' + this.client.id).update({'devis' : this.client.devis}).then(() => {
      this.router.navigateByUrl('/client/' + this.client.id);
    })
    .catch((error) => {
      this.showLoading = false;
      this.error = true;
      this.errorMessage = "Une erreur s'est produite : " + error;
    });
  }
}
