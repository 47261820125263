<div class="loading" *ngIf="showLoading">
  <div class="loader">
    <span class="dot dot_1"></span>
    <span class="dot dot_2"></span>
    <span class="dot dot_3"></span>
    <span class="dot dot_4"></span>
  </div>
</div>
<div class="content" *ngIf="!showLoading && intervention">
  <div class="form-row">
    <div class="form-group col-md-9">
      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{errorMessage}}}
      </div>
    </div>
    <div class="form-group col-md-3" style="text-align:right" *ngIf="showConfirmDelete">
      <button type="button" class="btn btn-outline-info btn-sm" (click)="deleteIntervention()" style="margin-right: 10px;">Confirmer</button>
      <button type="button" class="btn btn-outline-info btn-sm" (click)="showConfirmDelete = false">Annuler</button>
    </div>
    <div class="form-group col-md-3" style="text-align:right" *ngIf="!showConfirmDelete">
      <button type="button" class="btn btn-outline-info btn-sm" (click)="editIntervention()" style="margin-right: 10px;">Modifier</button>
      <button type="button" class="btn btn-outline-info btn-sm" (click)="showConfirmDelete = true" style="margin-right: 10px;">Supprimer</button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeIntervention()">Fermer</button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <span style="float:left;font-size: 30px;color:#3097D1">{{intervention.title}}</span>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      {{intervention.date | date:'dd-MM-yyyy'}}
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <hr>
      <strong>Fichier PDF</strong>
    </div>
    <div class="form-group col-md-6" *ngIf="intervention.file">
      <hr>
      <a href="{{intervention.file.url}}" target="_blank">{{intervention.file.name}}</a>
    </div>
    <div class="form-group col-md-6" *ngIf="!intervention.file">
      <hr>
      <span>Pas de Fichier</span>
    </div>
    <hr>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <hr>
      <p [innerHTML]="intervention.description"></p>
    </div>
    <hr>
  </div>
</div>