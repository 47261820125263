export interface Client {
  id: string;
  firstname: string;
  lastname: string;
  mobileNumber: string;
  fixNumber: string;
  email: string;
  tva: string;
  remark: string;
  addresses: Array<Address>;
  interventions: Array<Intervention>;
  devis: Array<Devis>;
}

export interface Address {
  id: string;
  street: string;
  postalCode: string;
  city: string;
  facturation: boolean;
}

export interface SearchAddress {
  userId: string;
  address: Address;
}

export interface Intervention {
  id: string;
  title: string;
  date: Date;
  file: PdfFile;
  description: string;
}

export interface Devis {
  id: string;
  title: string;
  date: Date;
  file: PdfFile;
  description: string;
}

export interface PdfFile {
  name: string;
  url: string;
}

export interface ImageFile {
  name: string;
  url: string;
}

export class FileUpload {
  $key: string;
  name: string;
  url: string;
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}

export interface Appointment {
  id: string;
  agenda: string;
  startDate: number;
  endDate: number;
  client: Client;
  address: Address;
  isBilled:boolean;
  receipt:boolean;
  highlightedRemark: string;
  remark: string;
  file: PdfFile;
  image1: ImageFile;
  image2: ImageFile;
  image3: ImageFile;
}

export interface Team {
  id: string;
  agenda: string;
  chief: string;
  email: string;
  password: string;
}

export interface Agenda {
  id: string;
  team: Team;
}

export interface AgendaRemark {
  id: string;
  agenda: string;
  date: number;
  remark: string;
}
