<div class="loading" *ngIf="showLoader">
  <div class="loader">
    <span class="dot dot_1"></span>
    <span class="dot dot_2"></span>
    <span class="dot dot_3"></span>
    <span class="dot dot_4"></span>
  </div>
</div>
<div *ngIf="!showLoader">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="currentTab == 'general' ? 'active' : ''" (click)="currentTab = 'general'">
        <i class="fa fa-user" aria-hidden="true"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="currentTab == 'interventions' ? 'active' : ''"
        (click)="currentTab = 'interventions'">
        <i class="fa fa-wrench" aria-hidden="true"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="currentTab == 'devis' ? 'active' : ''" (click)="currentTab = 'devis'">
        <i class="fa fa-eur" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
  <div class="content" [hidden]="currentTab != 'general'">
    <div class="form-row">
      <div class="form-group col-md-8">
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{ errorMessage }}}
        </div>
        <div *ngIf="!error">
          <button type="button" class="btn btn-outline-info btn-sm" (click)="previousClient()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-outline-info btn-sm" (click)="nextClient()">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="form-group col-md-4" style="text-align:right" *ngIf="showConfirmDelete">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="deleteClient()" style="margin-right: 10px;">
          Confirmer
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="showConfirmDelete = false"
          style="margin-right: 10px;">
          Annuler
        </button>
      </div>
      <div class="form-group col-md-4" style="text-align:right" *ngIf="!showConfirmDelete">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="editClient()" style="margin-right: 10px;">
          Modifier
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="showTransfer = true"
          style="margin-right: 10px;">
          Transférer
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="showConfirmDelete = true"
          style="margin-right: 10px;">
          Supprimer
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeClient()" style="margin-right: 10px;">
          Fermer
        </button>
      </div>
    </div>
    <app-transfert-client *ngIf="showTransfer" [client]="client" (onClose)="showTransfer = false">
    </app-transfert-client>
    <div class="form-row">
      <div class="form-group col-md-12">
        <span style="float:left;font-size: 30px;color:#3097D1">{{ client.lastname }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <span style="float:left;font-size: 20px;color:#3097D1">{{
          client.firstname
        }}</span>
      </div>
    </div>
    <hr />
    <div class="form-row">
      <div class="form-group col-md-8">
        <div class="form-row">
          <div class="form-group col-md-6">
            <strong>Numéro de mobile</strong>
          </div>
          <div class="form-group col-md-6">
            <label><a href="tel:{{ client.mobileNumber }}">{{
                client.mobileNumber
              }}</a></label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <strong>Numéro de fixe</strong>
          </div>
          <div class="form-group col-md-6">
            <label><a href="tel:{{ client.fixNumber }}">{{
                client.fixNumber
              }}</a></label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <strong>Adresse mail</strong>
          </div>
          <div class="form-group col-md-6">
            <label><a href="mailto:{{ client.email }}">{{ client.email }}</a></label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <strong>Numéro de TVA</strong>
          </div>
          <div class="form-group col-md-6">
            <label>{{ client.tva }}</label>
          </div>
        </div>
      </div>
      <div class="form-group col-md-4">
        <strong>Remarques</strong>
        <p [innerHTML]="client.remark"></p>
      </div>
      <strong>Adresses</strong>
      <table class="table table-condensed" style="margin-top:10px;" *ngIf="client">
        <tbody>
          <tr *ngFor="let address of client.addresses">
            <td style="width: 50%;">{{ address.street }}</td>
            <td style="width: 20%;">{{ address.postalCode }}</td>
            <td style="width: 30%;">{{ address.city }}</td>
            <td style="width: 10%;" *ngIf="address.facturation">
              <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td style="width: 10%;" *ngIf="!address.facturation"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="content" [hidden]="currentTab != 'interventions'">
    <div class="form-row">
      <div class="form-group col-md-9"></div>
      <div class="form-group col-md-3" style="text-align:right">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="addIntervetion()"
          style="margin-right: 10px;">
          Ajouter
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeClient()" style="margin-right: 10px;">
          Fermer
        </button>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <span style="float:left;font-size: 30px;color:#3097D1">Factures</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <table class="table table-hover" style="margin-top:10px;">
          <thead>
            <tr>
              <th>Date</th>
              <th>Titre</th>
              <th>Fichier PDF</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let intervention of (client.interventions
                  | orderBy: 'date':true)
              " style="cursor:pointer;">
              <td style="width: 20%;" (click)="openIntervention(intervention.id)">
                {{ intervention.date | date: "dd-MM-yyyy" }}
              </td>
              <td style="width: 50%;" (click)="openIntervention(intervention.id)">
                {{ intervention.title }}
              </td>
              <td style="width: 30%;" *ngIf="intervention.file">
                <a href="{{ intervention.file.url }}" target="_blank">{{
                  intervention.file.name
                }}</a>
              </td>
              <td style="width: 30%;" *ngIf="!intervention.file"></td>
            </tr>
          </tbody>
        </table>
        <p class="no-result" *ngIf="client.interventions.length == 0">
          Pas de facture...
        </p>
      </div>
    </div>
  </div>
  <div class="content" [hidden]="currentTab != 'devis'">
    <div class="form-row">
      <div class="form-group col-md-9"></div>
      <div class="form-group col-md-3" style="text-align:right">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="addDevis()" style="margin-right: 10px;">
          Ajouter
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeClient()" style="margin-right: 10px;">
          Fermer
        </button>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <span style="float:left;font-size: 30px;color:#3097D1">Devis</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <table class="table table-hover" style="margin-top:10px;">
          <thead>
            <tr>
              <th>Date</th>
              <th>Titre</th>
              <th>Fichier PDF</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let devis of (client.devis | orderBy: 'date':true)" style="cursor:pointer;">
              <td style="width: 20%;" (click)="openDevis(devis.id)">
                {{ devis.date | date: "dd-MM-yyyy" }}
              </td>
              <td style="width: 50%;" (click)="openDevis(devis.id)">
                {{ devis.title }}
              </td>
              <td style="width: 30%;" *ngIf="devis.file">
                <a href="{{ devis.file.url }}" target="_blank">{{
                  devis.file.name
                }}</a>
              </td>
              <td style="width: 30%;" *ngIf="!devis.file"></td>
            </tr>
          </tbody>
        </table>
        <p class="no-result" *ngIf="client.devis.length == 0">
          Pas de devis...
        </p>
      </div>
    </div>
  </div>
</div>
