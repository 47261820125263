<div class="loading" *ngIf="showLoading">
  <div class="loader">
      <span class="dot dot_1"></span>
      <span class="dot dot_2"></span>
      <span class="dot dot_3"></span>
      <span class="dot dot_4"></span>
  </div>
</div>
<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentTab=='general') ? 'active' : ''" (click)="currentTab = 'general'">
      <i class="fa fa-user" aria-hidden="true"></i>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentTab=='addresses') ? 'active' : ''" (click)="currentTab = 'addresses'">
      <i class="fa fa-home" aria-hidden="true"></i>
    </a>
  </li>
</ul>
<div class="content">
  <form [formGroup]="clientForm" (submit)="submitClientForm(clientForm.value)" [hidden]="currentTab != 'general'">
    <div class="form-row">
      <div class="form-group col-md-12">
        <span style="float:left;font-size: 30px;color:#3097D1">Informations</span>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="close()" style="float:right">Fermer</button>
      </div>
    </div>
    <hr>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="lastname">Nom</label>
        <input type="text" class="form-control" id="lastname" [formControl]="clientForm.controls['lastname']">
      </div>
      <div class="form-group col-md-6">
        <label for="firstname">Prénom</label>
        <input type="text" class="form-control" id="firstname" [formControl]="clientForm.controls['firstname']">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="mobileNumber">Numéro de mobile</label>
        <input type="text" class="form-control" id="mobileNumber" [formControl]="clientForm.controls['mobileNumber']">
      </div>
      <div class="form-group col-md-6">
        <label for="fixNumber">Numéro de fixe</label>
        <input type="text" class="form-control" id="fixNumber" [formControl]="clientForm.controls['fixNumber']">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="email">Email</label>
        <input type="text" class="form-control" id="email" [formControl]="clientForm.controls['email']">
      </div>
      <div class="form-group col-md-6">
        <label for="tva">Numéro de TVA</label>
        <input type="text" class="form-control" id="tva" [formControl]="clientForm.controls['tva']">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="remark">Remarque</label>
        <textarea class="form-control" id="remark" rows="10" [froalaEditor]='options' [formControl]="clientForm.controls['remark']"></textarea>
      </div>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="clientFormError">
      {{clientFormErrorMessage}}
    </div>
    <button type="submit" class="btn btn-success" [disabled]="!clientForm.valid">Enregistrer</button>
  </form>
  <div [hidden]="currentTab != 'addresses'">
    <div class="form-row">
      <div class="form-group col-md-12">
        <span style="float:left;font-size: 30px;color:#3097D1">Adresses</span>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="close()" style="float:right">Fermer</button>
      </div>
    </div>
    <hr>
    <div class="form-row">
      <div class="form-group col-md-6">
        <form [formGroup]="adressForm" (submit)="submitAddressForm(adressForm.value)">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Rue</label>
              <input type="text" class="form-control" id="street" [formControl]="adressForm.controls['street']">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Code postal</label>
              <input type="text" class="form-control" id="postalCode" [formControl]="adressForm.controls['postalCode']">
            </div>
            <div class="form-group col-md-8">
              <label for="inputEmail4">Ville</label>
              <input type="text" class="form-control" id="city" [formControl]="adressForm.controls['city']">
            </div>
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="!adressForm.valid">Ajouter</button>
        </form>
      </div>
      <div class="form-group col-md-6">
        <table class="table table-condensed">
          <thead *ngIf="clientAddresses.length != 0">
          </thead>
          <tbody>
            <tr *ngIf="clientAddresses.length == 0">
              <td style="border-top:none !important;text-align:center">
                Pas d'adresse...
              </td>
            </tr>
            <tr *ngFor="let address of clientAddresses">
              <td style="width: 70%;">
                {{address.street}} - {{address.city}}
              </td>
              <td style="width: 10%;text-align:center">
                <input class="form-check-input" type="checkbox" [(ngModel)]="address.facturation" (change)="setFacturationAddress(address.id)"
                  [disabled]="clientAddresses.length == 1">
              </td>
              <td style="width: 20%;text-align:right">
                <button type="button" class="btn btn-outline-danger btn-sm" (click)="removeAddress(address)">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>