import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  showLoader: boolean = false;
  loginFromError: boolean = false;
  loginForm: FormGroup;

  constructor(
    public afAuth: AngularFireAuth,
    public formBuilder: FormBuilder,
    public router: Router,
    public app: AppComponent) {
    this.loginForm = formBuilder.group({
      'mail': [null, Validators.email],
      'password': [null, Validators.required]
    });
  }

  ngOnInit() {
  }

  submitForm(value: any): void {
    var that = this;
    that.showLoader = true;
    if(!value.mail || !value.password){
      that.showLoader = false;
      that.loginFromError = true;
    }else{
      that.afAuth.signInWithEmailAndPassword(value.mail, value.password)
      .then(() => {
        that.showLoader = false;
        that.router.navigate(['/clients']);
      }).catch((error) => {
        that.showLoader = false;
        that.loginFromError = true;
      });
    }
  }
}
