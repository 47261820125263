import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Appointment, Agenda, AgendaRemark } from '../../models/models';

@Component({
  selector: 'app-agenda-lock',
  templateUrl: './agenda-lock.component.html',
  styleUrls: ['./agenda-lock.component.css'],
})
export class AgendaLockComponent implements OnInit {
  @Input() agenda: Agenda;
  showLoader = false;
  currentDate: Date;
  showPopup = false;
  appointment: Appointment;
  hours = [];
  minutes = [];
  monday: Date;
  mondayRemark: AgendaRemark;
  tuesday: Date;
  tuesdayRemark: AgendaRemark;
  wednesday: Date;
  wednesdayRemark: AgendaRemark;
  thursday: Date;
  thursdayRemark: AgendaRemark;
  friday: Date;
  fridayRemark: AgendaRemark;
  saturday: Date;
  saturdayRemark: AgendaRemark;
  sunday: Date;
  sundayRemark: AgendaRemark;
  table = [];
  mobileTable = [];

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    for (var i = 0; i <= 21; i++) {
      this.hours.push(
        i.toString().length == 1 ? '0' + i.toString() : i.toString()
      );
    }
    for (var i = 0; i <= 45; i += 15) {
      this.minutes.push(
        i.toString().length == 1 ? '0' + i.toString() : i.toString()
      );
    }
    this.resetCalendarPosition();
  }

  resetCalendarPosition() {
    this.showLoader = true;
    this.currentDate = new Date();
    var previousMonday = new Date();
    previousMonday.setDate(
      previousMonday.getDate() + ((1 - 7 - previousMonday.getDay()) % 7)
    );
    var nextMonday = new Date();
    nextMonday.setDate(
      nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
    );
    var weekDay = new Date().getDay();
    if (weekDay == 0 || weekDay == 6) {
      this.monday = nextMonday;
    } else {
      this.monday = previousMonday;
    }
    this.setDaysOfWeek(this.monday);
  }

  setDaysOfWeek(monday: Date) {
    this.monday = monday;
    var tuesday = new Date(monday);
    tuesday.setDate(monday.getDate() + 1);
    this.tuesday = tuesday;
    var wednesday = new Date(monday);
    wednesday.setDate(monday.getDate() + 2);
    this.wednesday = wednesday;
    var thursday = new Date(monday);
    thursday.setDate(monday.getDate() + 3);
    this.thursday = thursday;
    var friday = new Date(monday);
    friday.setDate(monday.getDate() + 4);
    this.friday = friday;
    var saturday = new Date(monday);
    saturday.setDate(monday.getDate() + 5);
    this.saturday = saturday;
    var sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    this.sunday = sunday;
    var start = new Date(this.monday.setHours(7, 0, 0, 0));
    this.afs
      .collection<Appointment>('Calendar', (ref) =>
        ref
          .orderBy('startDate')
          .where('agenda', '==', this.agenda.id)
          .where('startDate', '>=', start.getDate())
      )
      .valueChanges()
      .subscribe((data) => {
        this.tableConstruct(data);
        this.mobileTableConstruct(data);
      });
  }

  tableConstruct(param) {
    this.table = [];
    var hours = 6;
    var minutes = 45;
    while (hours <= 20) {
      minutes = minutes + 15;
      if (minutes == 60) {
        hours = hours + 1;
        minutes = 0;
      }
      var row = {
        time:
          (hours.toString().length == 1
            ? '0' + hours.toString()
            : hours.toString()) +
          ':' +
          (minutes.toString().length == 1
            ? minutes.toString() + '0'
            : minutes.toString()),
        dates: [
          {
            date: new Date(this.monday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.monday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.tuesday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.tuesday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.wednesday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.wednesday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.thursday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.thursday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.friday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.friday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.saturday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.saturday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.sunday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.sunday.setHours(hours, minutes, 0, 0)
            ),
          },
        ],
      };
      this.table.push(row);
    }
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.monday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.mondayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.tuesday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.tuesdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.wednesday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.wednesdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.thursday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.thursdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.friday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.fridayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.saturday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.saturdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.sunday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.sundayRemark = data[0];
        }
      });
    this.showLoader = false;
  }

  mobileTableConstruct(param: Appointment[]) {
    this.mobileTable = [];
    var currentDate = this.currentDate;
    var hours = 6;
    var minutes = 45;
    while (hours <= 20) {
      minutes = minutes + 15;
      if (minutes == 60) {
        hours = hours + 1;
        minutes = 0;
      }
      var row = {
        time:
          (hours.toString().length == 1
            ? '0' + hours.toString()
            : hours.toString()) +
          ':' +
          (minutes.toString().length == 1
            ? minutes.toString() + '0'
            : minutes.toString()),
        dates: [
          {
            date: new Date(currentDate.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              currentDate.setHours(hours, minutes, 0, 0)
            ),
          },
        ],
      };
      this.mobileTable.push(row);
    }
  }

  findAppointment(array: Appointment[], date: number): Appointment {
    var now = new Date();
    now.setDate(now.getDate() + 7);
    now.setHours(21, 0);
    for (var i = 0; i < array.length; i += 1) {
      if (
        array[i]['startDate'] == date &&
        array[i]['startDate'] <= now.getTime()
      ) {
        var diff = Math.abs(array[i]['startDate'] - array[i]['endDate']);
        var minutes = Math.floor(diff / 1000 / 60);
        var size = minutes / 15;
        array[i]['size'] = 26 * size;
        return array[i];
      }
    }
    return null;
  }

  previousDay() {
    var currentDate = this.currentDate;
    currentDate.setDate(currentDate.getDate() - 1);
    if (currentDate.getDay() == 0) {
      currentDate.setDate(currentDate.getDate() - 2);
    }
    if (currentDate.getDay() == 6) {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    this.currentDate = new Date(currentDate);
    var monday = this.getMonday(this.currentDate);
    this.setDaysOfWeek(monday);
  }

  nextDay() {
    var currentDate = this.currentDate;
    currentDate.setDate(currentDate.getDate() + 1);
    if (currentDate.getDay() == 0) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (currentDate.getDay() == 6) {
      currentDate.setDate(currentDate.getDate() + 2);
    }
    this.currentDate = new Date(currentDate);
    var monday = this.getMonday(this.currentDate);
    this.setDaysOfWeek(monday);
  }

  previousWeek() {
    var previousMonday = new Date(this.monday);
    previousMonday.setDate(this.monday.getDate() - 7);
    this.monday = previousMonday;
    this.setDaysOfWeek(previousMonday);
  }

  nextWeek() {
    var nextMonday = new Date(this.monday);
    nextMonday.setDate(this.monday.getDate() + 7);
    this.monday = nextMonday;
    this.setDaysOfWeek(nextMonday);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  openPopup(date, appointment) {
    this.appointment = appointment;
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
    this.appointment = {
      id: '',
      agenda: this.agenda.id,
      startDate: 0,
      endDate: 0,
      client: null,
      address: null,
      isBilled : false,
      receipt : false,
      highlightedRemark: '',
      remark: '',
      file: null,
      image1: null,
      image2: null,
      image3: null,
    };
  }
}
