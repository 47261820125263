import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import {
  Client,
  Appointment,
  Agenda,
  AgendaRemark,
  PdfFile,
  ImageFile,
  FileUpload,
} from '../../models/models';
import { Document, Paragraph, Packer, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
})
export class AgendaComponent implements OnInit {
  @Input() agenda: Agenda;
  options: Object = {
    key: 'RA-7sA-31B-22d1zE-11vA-8I2vA-21sD-16xesD3i1kh==',
    language: 'fr',
    height: '300',
    placeholderText: '',
    quickInsertButtons: [],
    charCounterCount: true,
    toolbarButtons: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'color',
      '|',
      'formatOL',
      'formatUL',
      'insertTable',
      'insertFile',
      'insertImage',
      'insertVideo',
      'insertLink',
      'html',
    ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    colorsStep: 6,
    colorsText: [
      '#15E67F',
      '#E3DE8C',
      '#D8A076',
      '#D83762',
      '#76B6D8',
      'REMOVE',
      '#1C7A90',
      '#249CB8',
      '#4ABED9',
      '#FBD75B',
      '#FBE571',
      '#FFFFFF',
    ],
  };
  showLoader = false;
  showAddPopup = false;
  showRemarkPopup = false;
  popupConsultMode = false;
  confirmDelete = false;
  clients: Client[] = [];
  searchResult: Client[] = [];
  appointment: Appointment;
  currentDate: Date;
  selectedDate: Date;
  hours = [];
  minutes = [];
  monday: Date;
  mondayRemark: AgendaRemark;
  tuesday: Date;
  tuesdayRemark: AgendaRemark;
  wednesday: Date;
  wednesdayRemark: AgendaRemark;
  thursday: Date;
  thursdayRemark: AgendaRemark;
  friday: Date;
  fridayRemark: AgendaRemark;
  saturday: Date;
  saturdayRemark: AgendaRemark;
  sunday: Date;
  sundayRemark: AgendaRemark;
  table = [];
  mobileTable = [];
  dateForm: FormGroup = this.formBuilder.group({
    date: [null],
  });
  formError = false;
  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  selectedImage1: FileList;
  currentFileUpload1: FileUpload;
  selectedImage2: FileList;
  currentFileUpload2: FileUpload;
  selectedImage3: FileList;
  currentFileUpload3: FileUpload;
  appointementForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    startDate: [{ value: null }, Validators.required],
    startHours: ['00', Validators.required],
    startMinutes: ['00', Validators.required],
    endDate: [{ value: null, disabled: true }, Validators.required],
    endHours: ['00', Validators.required],
    endMinutes: ['00', Validators.required],
    isBilled: [false],
    receipt: [false],
    highlightedRemark: [null],
    remark: [null],
  });

  remarkForm: FormGroup = this.formBuilder.group({
    id: [null],
    date: [null, Validators.required],
    remark: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    for (var i = 0; i <= 21; i++) {
      this.hours.push(
        i.toString().length == 1 ? '0' + i.toString() : i.toString()
      );
    }
    for (var i = 0; i <= 45; i += 15) {
      this.minutes.push(
        i.toString().length == 1 ? '0' + i.toString() : i.toString()
      );
    }
    this.appointment = {
      id: '',
      agenda: this.agenda.id,
      startDate: 0,
      endDate: 0,
      client: null,
      address: null,
      isBilled: false,
      receipt:false,
      highlightedRemark: '',
      remark: '',
      file: null,
      image1: null,
      image2: null,
      image3: null,
    };
    this.afs
      .collection<Client>('Clients', (ref) => ref.orderBy('lastname'))
      .valueChanges()
      .subscribe((data) => {
        this.clients = data;
      });
    this.resetCalendarPosition();
  }

  tableConstruct(param) {
    this.table = [];
    var hours = 6;
    var minutes = 45;
    while (hours <= 20) {
      minutes = minutes + 15;
      if (minutes == 60) {
        hours = hours + 1;
        minutes = 0;
      }
      var row = {
        time:
          (hours.toString().length == 1
            ? '0' + hours.toString()
            : hours.toString()) +
          ':' +
          (minutes.toString().length == 1
            ? minutes.toString() + '0'
            : minutes.toString()),
        dates: [
          {
            date: new Date(this.monday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.monday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.tuesday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.tuesday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.wednesday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.wednesday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.thursday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.thursday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.friday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.friday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.saturday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.saturday.setHours(hours, minutes, 0, 0)
            ),
          },
          {
            date: new Date(this.sunday.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              this.sunday.setHours(hours, minutes, 0, 0)
            ),
          },
        ],
      };
      this.table.push(row);
    }
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.monday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.mondayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.tuesday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.tuesdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.wednesday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.wednesdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.thursday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.thursdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.friday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.fridayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.saturday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.saturdayRemark = data[0];
        }
      });
    this.afs
      .collection<AgendaRemark>('AgendaRemarks', (ref) =>
        ref
          .where('agenda', '==', this.agenda.id)
          .where('date', '==', this.sunday.getTime())
      )
      .valueChanges()
      .subscribe((data) => {
        if (data[0]) {
          this.sundayRemark = data[0];
        }
      });
    this.showLoader = false;
  }

  mobileTableConstruct(param: Appointment[]) {
    this.mobileTable = [];
    var currentDate = this.currentDate;
    var hours = 6;
    var minutes = 45;
    while (hours <= 20) {
      minutes = minutes + 15;
      if (minutes == 60) {
        hours = hours + 1;
        minutes = 0;
      }
      var row = {
        time:
          (hours.toString().length == 1
            ? '0' + hours.toString()
            : hours.toString()) +
          ':' +
          (minutes.toString().length == 1
            ? minutes.toString() + '0'
            : minutes.toString()),
        dates: [
          {
            date: new Date(currentDate.setHours(hours, minutes, 0, 0)),
            appointment: this.findAppointment(
              param,
              currentDate.setHours(hours, minutes, 0, 0)
            ),
          },
        ],
      };
      this.mobileTable.push(row);
    }
  }

  findAppointment(array: Appointment[], date: number): Appointment {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i]['startDate'] == date) {
        var diff = Math.abs(array[i]['startDate'] - array[i]['endDate']);
        var minutes = Math.floor(diff / 1000 / 60);
        var size = minutes / 15;
        array[i]['size'] = 26 * size;
        return array[i];
      }
    }
    return null;
  }

  resetCalendarPosition() {
    this.showLoader = true;
    this.currentDate = new Date();
    this.dateForm.controls['date'].setValue('');
    var previousMonday = new Date();
    previousMonday.setDate(
      previousMonday.getDate() + ((1 - 7 - previousMonday.getDay()) % 7)
    );
    var nextMonday = new Date();
    nextMonday.setDate(
      nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
    );
    var weekDay = new Date().getDay();
    if (weekDay == 0 || weekDay == 6) {
      this.monday = nextMonday;
    } else {
      this.monday = previousMonday;
    }
    this.setDaysOfWeek(this.monday);
  }

  setDaysOfWeek(monday: Date) {
    this.monday = monday;
    var tuesday = new Date(monday);
    tuesday.setDate(monday.getDate() + 1);
    this.tuesday = tuesday;
    var wednesday = new Date(monday);
    wednesday.setDate(monday.getDate() + 2);
    this.wednesday = wednesday;
    var thursday = new Date(monday);
    thursday.setDate(monday.getDate() + 3);
    this.thursday = thursday;
    var friday = new Date(monday);
    friday.setDate(monday.getDate() + 4);
    this.friday = friday;
    var saturday = new Date(monday);
    saturday.setDate(monday.getDate() + 5);
    this.saturday = saturday;
    var sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    this.sunday = sunday;
    var start = new Date(this.monday.setHours(7, 0, 0, 0));
    this.afs
      .collection<Appointment>('Calendar', (ref) =>
        ref
          .orderBy('startDate')
          .where('agenda', '==', this.agenda.id)
          .where('startDate', '>=', start.getDate())
      )
      .valueChanges()
      .subscribe((data) => {
        this.tableConstruct(data);
        this.mobileTableConstruct(data);
      });
  }

  openPopup(date, appointment) {
    if (!appointment) {
      this.showAddPopup = true;
      this.popupConsultMode = false;
      this.appointment.startDate = date.getTime();
      var selectedDay =
        date.getDate().toString().length == 1
          ? '0' + date.getDate().toString()
          : date.getDate().toString();
      var selectedMonth =
        (date.getMonth() + 1).toString().length == 1
          ? '0' + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString();
      var selectedYear = date.getFullYear();
      var selectedHours =
        date.getHours().toString().length == 1
          ? '0' + date.getHours().toString()
          : date.getHours().toString();
      var endHours = date.getHours() + 1;
      var selectedEndHours =
        endHours.toString().length == 1
          ? '0' + endHours.toString()
          : endHours.toString();
      var selectedMinutes =
        date.getMinutes().toString().length == 1
          ? '0' + date.getMinutes().toString()
          : date.getMinutes().toString();
      this.appointementForm.controls['startDate'].setValue(
        selectedDay + '-' + selectedMonth + '-' + selectedYear
      );
      this.appointementForm.controls['startHours'].setValue(selectedHours);
      this.appointementForm.controls['startMinutes'].setValue(selectedMinutes);
      this.appointementForm.controls['endDate'].setValue(
        selectedDay + '-' + selectedMonth + '-' + selectedYear
      );
      this.appointementForm.controls['endHours'].setValue(selectedEndHours);
      this.appointementForm.controls['endMinutes'].setValue(selectedMinutes);
    } else {
      this.appointment = appointment;
      if (this.appointment.file) {
        var storageRef = firebase.storage().ref();
        storageRef
          .child('/appointment/' + this.appointment.file.name)
          .getDownloadURL()
          .then((url) => {
            this.appointment.file.url = url;
          });
      }
      if (this.appointment.image1) {
        var storageRef = firebase.storage().ref();
        storageRef
          .child('/appointment/' + this.appointment.image1.name)
          .getDownloadURL()
          .then((url) => {
            this.appointment.image1.url = url;
          });
      }
      if (this.appointment.image2) {
        var storageRef = firebase.storage().ref();
        storageRef
          .child('/appointment/' + this.appointment.image2.name)
          .getDownloadURL()
          .then((url) => {
            this.appointment.image2.url = url;
          });
      }
      if (this.appointment.image3) {
        var storageRef = firebase.storage().ref();
        storageRef
          .child('/appointment/' + this.appointment.image3.name)
          .getDownloadURL()
          .then((url) => {
            this.appointment.image3.url = url;
          });
      }
      this.showAddPopup = true;
      this.popupConsultMode = true;
    }
  }

  previousDay() {
    var currentDate = this.currentDate;
    currentDate.setDate(currentDate.getDate() - 1);
    if (currentDate.getDay() == 0) {
      currentDate.setDate(currentDate.getDate() - 2);
    }
    if (currentDate.getDay() == 6) {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    this.currentDate = new Date(currentDate);
    var monday = this.getMonday(this.currentDate);
    this.setDaysOfWeek(monday);
  }

  nextDay() {
    var currentDate = this.currentDate;
    currentDate.setDate(currentDate.getDate() + 1);
    if (currentDate.getDay() == 0) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (currentDate.getDay() == 6) {
      currentDate.setDate(currentDate.getDate() + 2);
    }
    this.currentDate = new Date(currentDate);
    var monday = this.getMonday(this.currentDate);
    this.setDaysOfWeek(monday);
  }

  previousWeek() {
    this.dateForm.controls['date'].setValue('');
    var previousMonday = new Date(this.monday);
    previousMonday.setDate(this.monday.getDate() - 7);
    this.monday = previousMonday;
    this.setDaysOfWeek(previousMonday);
  }

  nextWeek() {
    this.dateForm.controls['date'].setValue('');
    var nextMonday = new Date(this.monday);
    nextMonday.setDate(this.monday.getDate() + 7);
    this.monday = nextMonday;
    this.setDaysOfWeek(nextMonday);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  dateFormSubmitMobile(value: any) {
    if (value.date) {
      var day = value.date.substring(0, 2);
      var month = value.date.substring(2, 4);
      var year = value.date.substring(4, 8);
      var selectedDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      this.currentDate = selectedDate;
      var monday = this.getMonday(selectedDate);
      this.setDaysOfWeek(monday);
    }
  }

  dateFormSubmit(value: any) {
    if (value.date) {
      var day = value.date.substring(0, 2);
      var month = value.date.substring(2, 4);
      var year = value.date.substring(4, 8);
      var selectedDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      selectedDate.setDate(
        selectedDate.getDate() + ((1 - 7 - selectedDate.getDay()) % 7)
      );
      this.monday = selectedDate;
      this.setDaysOfWeek(this.monday);
    }
  }

  onValueChange(searchValue: string) {
    if (searchValue) {
      this.searchResult = this.clients
        .filter((x) =>
          (x.lastname.trim() + ' ' + x.firstname).startsWith(searchValue)
        )
        .slice(0, 5);
    } else {
      this.searchResult = [];
    }
  }

  selectClient(selectedClient) {
    this.appointment.client = selectedClient;
    this.searchResult = [];
    this.appointementForm.controls['name'].setValue(
      this.appointment.client.lastname +
        (this.appointment.client.firstname
          ? ' ' + this.appointment.client.firstname
          : '')
    );
    if (this.appointment.client.addresses.length == 1) {
      this.appointment.address = this.appointment.client.addresses[0];
    }
  }

  selectAddress(selectedAddress) {
    this.appointment.address = selectedAddress;
  }

  closePopup() {
    this.showAddPopup = false;
    this.confirmDelete = false;
    this.formError = false;
    this.appointementForm.reset();
    this.searchResult = [];
    this.appointment = {
      id: '',
      agenda: this.agenda.id,
      startDate: 0,
      endDate: 0,
      client: null,
      address: null,
      isBilled: false,
      receipt:false,
      highlightedRemark: '',
      remark: '',
      file: null,
      image1: null,
      image2: null,
      image3: null,
    };
  }

  deleteAppointment(data : Appointment) {
    this.afs
      .collection('Calendar')
      .doc(data.id)
      .delete()
      .then(() => {
        this.closePopup();
      });
  }

  editAppointment(value) {
    this.appointment = value;
    this.showAddPopup = true;
    this.popupConsultMode = false;
    this.appointementForm.controls['name'].setValue(
      this.appointment.client.lastname +
        (this.appointment.client.firstname
          ? ' ' + this.appointment.client.firstname
          : '')
    );
    var startDate = new Date(value.startDate);
    var endDate = new Date(value.endDate);
    var selectedStartDay =
      startDate.getDate().toString().length == 1
        ? '0' + startDate.getDate().toString()
        : startDate.getDate().toString();
    var selectedStartMonth =
      (startDate.getMonth() + 1).toString().length == 1
        ? '0' + (startDate.getMonth() + 1).toString()
        : (startDate.getMonth() + 1).toString();
    var selectedStartYear = startDate.getFullYear();
    var selectedStartHours =
      startDate.getHours().toString().length == 1
        ? '0' + startDate.getHours().toString()
        : startDate.getHours().toString();
    var selectedStartMinutes =
      startDate.getMinutes().toString().length == 1
        ? '0' + startDate.getMinutes().toString()
        : startDate.getMinutes().toString();
    var selectedEndDay =
      endDate.getDate().toString().length == 1
        ? '0' + endDate.getDate().toString()
        : endDate.getDate().toString();
    var selectedEndMonth =
      (endDate.getMonth() + 1).toString().length == 1
        ? '0' + (endDate.getMonth() + 1).toString()
        : (endDate.getMonth() + 1).toString();
    var selectedEndYear = endDate.getFullYear();
    var selectedEndHours =
      endDate.getHours().toString().length == 1
        ? '0' + endDate.getHours().toString()
        : endDate.getHours().toString();
    var selectedEndMinutes =
      endDate.getMinutes().toString().length == 1
        ? '0' + endDate.getMinutes().toString()
        : endDate.getMinutes().toString();
    this.appointementForm.controls['startDate'].setValue(
      selectedStartDay + '-' + selectedStartMonth + '-' + selectedStartYear
    );
    this.appointementForm.controls['startHours'].setValue(selectedStartHours);
    this.appointementForm.controls['startMinutes'].setValue(
      selectedStartMinutes
    );
    this.appointementForm.controls['endDate'].setValue(
      selectedEndDay + '-' + selectedEndMonth + '-' + selectedEndYear
    );
    this.appointementForm.controls['endHours'].setValue(selectedEndHours);
    this.appointementForm.controls['endMinutes'].setValue(selectedEndMinutes);
    this.appointementForm.controls['isBilled'].setValue(value.isBilled);
    this.appointementForm.controls['receipt'].setValue(value.receipt ? value.receipt : false);
    this.appointementForm.controls['highlightedRemark'].setValue(
      value.highlightedRemark
    );
    this.appointementForm.controls['remark'].setValue(value.remark);
    var selectedAddress = value.address;
    this.appointment.address = selectedAddress;
  }

  startDateChange(value) {
    this.appointementForm.controls['endDate'].setValue(value);
  }

  async submitForm() {
    var startDate = this.appointementForm.controls['startDate'].value;
    var startHours = this.appointementForm.controls['startHours'].value;
    var startMinutes = this.appointementForm.controls['startMinutes'].value;
    var endDate = this.appointementForm.controls['endDate'].value;
    var endHours = this.appointementForm.controls['endHours'].value;
    var endMinutes = this.appointementForm.controls['endMinutes'].value;
    if (
      !startDate ||
      !startHours ||
      !startMinutes ||
      !endDate ||
      !endHours ||
      !endMinutes
    ) {
      this.formError = true;
    } else {
      var startDay = 0;
      var startMonth = 0;
      var startYear = 0;
      if (startDate.toString().includes('-')) {
        var splitDate = startDate.split('-');
        startDay = splitDate[0];
        startMonth = splitDate[1];
        startYear = splitDate[2];
      } else {
        startDay = startDate.substring(0, 2);
        startMonth = startDate.substring(2, 4);
        startYear = startDate.substring(4, 8);
      }
      var endDay = 0;
      var endMonth = 0;
      var endYear = 0;
      if (endDate.toString().includes('-')) {
        var splitDate = endDate.split('-');
        endDay = splitDate[0];
        endMonth = splitDate[1];
        endYear = splitDate[2];
      } else {
        endDay = endDate.substring(0, 2);
        endMonth = endDate.substring(2, 4);
        endYear = endDate.substring(4, 8);
      }
      this.formError = false;
      this.appointment.id = this.appointment.id
        ? this.appointment.id
        : this.afs.createId();
      this.appointment.startDate = new Date(
        startYear,
        startMonth - 1,
        startDay,
        parseInt(startHours),
        parseInt(startMinutes),
        0,
        0
      ).getTime();
      this.appointment.endDate = new Date(
        endYear,
        endMonth - 1,
        endDay,
        parseInt(endHours),
        parseInt(endMinutes),
        0,
        0
      ).getTime();
      this.appointment.isBilled = this.appointementForm.controls[
        'isBilled'
      ].value;
      this.appointment.receipt = this.appointementForm.controls[
        'receipt'
      ].value;
      this.appointment.highlightedRemark = this.appointementForm.controls[
        'highlightedRemark'
      ].value;
      this.appointment.remark = this.appointementForm.controls['remark'].value;
      if (
        !this.appointment.startDate ||
        !this.appointment.endDate ||
        !this.appointment.client ||
        !this.appointment.address
      ) {
        this.formError = true;
      } else {
        if (this.appointment.endDate <= this.appointment.startDate) {
          this.formError = true;
        } else {
          this.formError = false;
          if (this.selectedFiles && this.selectedFiles.item(0)) {
            var file = this.selectedFiles.item(0);
            this.currentFileUpload = new FileUpload(file);
            var storageRef = firebase.storage().ref();
            var basePath = 'appointment';
            var pdfFileName = this.currentFileUpload.file.name;
            await storageRef
              .child(`${basePath}/${pdfFileName}`)
              .put(this.currentFileUpload.file);
            var url = await storageRef
              .child(`${basePath}/${pdfFileName}`)
              .getDownloadURL();
            var pdfFile: PdfFile = {
              name: pdfFileName,
              url: url,
            };
            this.appointment.file = pdfFile;
          }
          if (this.selectedImage1 && this.selectedImage1.item(0)) {
            var file = this.selectedImage1.item(0);
            this.currentFileUpload1 = new FileUpload(file);
            var storageRef = firebase.storage().ref();
            var basePath = 'appointment';
            var fileUploadName1 = this.currentFileUpload1.file.name;
            await storageRef
              .child(`${basePath}/${fileUploadName1}`)
              .put(this.currentFileUpload1.file);
            var url = await storageRef
              .child(`${basePath}/${fileUploadName1}`)
              .getDownloadURL();
            var imageFile: ImageFile = {
              name: fileUploadName1,
              url: url,
            };
            this.appointment.image1 = imageFile;
          }
          if (this.selectedImage2 && this.selectedImage2.item(0)) {
            var file = this.selectedImage2.item(0);
            this.currentFileUpload2 = new FileUpload(file);
            var storageRef = firebase.storage().ref();
            var basePath = 'appointment';
            var fileUploadName2 = this.currentFileUpload2.file.name;
            await storageRef
              .child(`${basePath}/${fileUploadName2}`)
              .put(this.currentFileUpload2.file);
            var url = await storageRef
              .child(`${basePath}/${fileUploadName2}`)
              .getDownloadURL();
            var imageFile: ImageFile = {
              name: fileUploadName2,
              url: url,
            };
            this.appointment.image2 = imageFile;
          }
          if (this.selectedImage3 && this.selectedImage3.item(0)) {
            var file = this.selectedImage3.item(0);
            this.currentFileUpload3 = new FileUpload(file);
            var storageRef = firebase.storage().ref();
            var basePath = 'appointment';
            var fileUploadName3 = this.currentFileUpload3.file.name;
            await storageRef
              .child(`${basePath}/${fileUploadName3}`)
              .put(this.currentFileUpload3.file);
            var url = await storageRef
              .child(`${basePath}/${fileUploadName3}`)
              .getDownloadURL();
            var imageFile: ImageFile = {
              name: fileUploadName3,
              url: url,
            };
            this.appointment.image3 = imageFile;
          }
          this.afs
            .collection('Calendar')
            .doc(this.appointment.id)
            .set(this.appointment)
            .then(() => {
              this.closePopup();
            });
        }
      }
    }
  }

  changeIsBilled(data) {
    this.afs
      .collection('Calendar')
      .doc(data.id)
      .update({ isBilled: data.isBilled });
  }

  changeReceipt(data) {
    this.afs
      .collection('Calendar')
      .doc(data.id)
      .update({ receipt: data.receipt });
  }

  showRemark(date, remark) {
    this.showRemarkPopup = true;
    if (remark) {
      this.remarkForm.controls['remark'].setValue(remark.remark);
      this.remarkForm.controls['id'].setValue(remark.id);
    } else {
      this.remarkForm.controls['remark'].setValue('');
      this.remarkForm.controls['id'].setValue('');
    }
    this.remarkForm.controls['date'].setValue(date.getTime());
  }

  submitRemarkForm(value) {
    var remark: AgendaRemark = {
      id: value.id ? value.id : this.afs.createId(),
      agenda: this.agenda.id,
      date: value.date,
      remark: value.remark,
    };
    this.afs
      .collection<AgendaRemark>('AgendaRemarks')
      .doc(remark.id)
      .set(remark)
      .then(() => {
        this.showRemarkPopup = false;
      });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  selectImage1(event) {
    this.selectedImage1 = event.target.files;
  }

  selectImage2(event) {
    this.selectedImage2 = event.target.files;
  }

  selectImage3(event) {
    this.selectedImage3 = event.target.files;
  }

  print(date) {
    var endDate = new Date(date).getTime();
    var startDate = new Date(date.setHours(7)).getTime();
    var selectedDay =
      date.getDate().toString().length == 1
        ? '0' + date.getDate()
        : date.getDate();
    var selectedMonth =
      (date.getMonth() + 1).toString().length == 1
        ? '0' + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString();
    var selectedYear = date.getFullYear();
    var formatedDate = selectedDay + '-' + selectedMonth + '-' + selectedYear;
    var document = new Document();
    document.addParagraph(new Paragraph(formatedDate).title());
    this.afs
      .collection<Appointment>('Calendar', (ref) =>
        ref
          .orderBy('startDate')
          .where('agenda', '==', this.agenda.id)
          .where('startDate', '>=', startDate)
      )
      .get()
      .subscribe((data) => {
        data.forEach((item) => {
          var appointment = item.data();
          document.addParagraph(new Paragraph());
          if (
            appointment.startDate >= startDate &&
            appointment.endDate <= endDate
          ) {
            var startHour =
              new Date(appointment.startDate).getHours().toString().length == 1
                ? '0' + new Date(appointment.startDate).getHours()
                : new Date(appointment.startDate).getHours();
            var startMinute =
              new Date(appointment.startDate).getMinutes().toString().length ==
              1
                ? '0' + new Date(appointment.startDate).getMinutes()
                : new Date(appointment.startDate).getMinutes();
            var endHour =
              new Date(appointment.endDate).getHours().toString().length == 1
                ? '0' + new Date(appointment.endDate).getHours()
                : new Date(appointment.endDate).getHours();
            var endMinute =
              new Date(appointment.endDate).getMinutes().toString().length == 1
                ? '0' + new Date(appointment.endDate).getMinutes()
                : new Date(appointment.endDate).getMinutes();
            document.addParagraph(
              this.createHeading(
                startHour +
                  ':' +
                  startMinute +
                  ' à ' +
                  endHour +
                  ':' +
                  endMinute
              )
            );
            document.addParagraph(new Paragraph());
            if (appointment.highlightedRemark) {
              var highlightedRemark = new TextRun(
                appointment.highlightedRemark
              );
              var highlightedRemarkParagraph = new Paragraph();
              highlightedRemarkParagraph.addRun(
                highlightedRemark.bold().allCaps().color('red').size(16)
              );
              document.addParagraph(highlightedRemarkParagraph);
              document.addParagraph(new Paragraph());
            }
            document.addParagraph(
              new Paragraph(
                (appointment.client.lastname
                  ? appointment.client.lastname
                  : '') +
                  ' ' +
                  (appointment.client.firstname
                    ? appointment.client.firstname
                    : '')
              )
            );
            document.addParagraph(new Paragraph());
            document.addParagraph(new Paragraph(appointment.address.street));
            document.addParagraph(
              new Paragraph(
                appointment.address.postalCode + ' ' + appointment.address.city
              )
            );
            document.addParagraph(new Paragraph());
            if (appointment.client.mobileNumber) {
              document.addParagraph(
                new Paragraph(appointment.client.mobileNumber)
              );
            }
            if (appointment.client.fixNumber) {
              document.addParagraph(
                new Paragraph(appointment.client.fixNumber)
              );
            }
            if (appointment.remark) {
              document.addParagraph(new Paragraph());
              var remark = appointment.remark;
              remark = remark.replace('</p>', '<br>');
              remark = remark.replace('<br>', '\n');
              remark = remark.replace(/<\/?[^>]+(>|$)/g, '');
              var parser = new DOMParser();
              var dom = parser.parseFromString(
                '<!doctype html><body>' + remark,
                'text/html'
              );
              var decodedString = dom.body.textContent;
              remark = decodedString;
              remark = remark.replace('Powered by Froala Editor', '');
              document.addParagraph(new Paragraph(remark));
            }
          }
        });
        const packer = new Packer();
        packer.toBlob(document).then((blob) => {
          saveAs(blob, formatedDate + '.docx');
        });
      });
  }

  createHeading(text) {
    return new Paragraph(text).heading1().thematicBreak();
  }
}
