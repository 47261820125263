<div class="loading" *ngIf="showLoader">
  <div class="loader">
      <span class="dot dot_1"></span>
      <span class="dot dot_2"></span>
      <span class="dot dot_3"></span>
      <span class="dot dot_4"></span>
  </div>
</div>
<div class="container login-container">
    <form class="form-horizontal" [formGroup]="loginForm" (submit)="submitForm(loginForm.value)">
      <div class="loginLogo"></div>
      <div class="form-content">
          <div class="alert alert-danger" role="alert" *ngIf="loginFromError">
              Nom d'utilisateur ou mot de passe incorrect
            </div>
        <div class="form-group">
          <input type="email" class="form-control" id="email" placeholder="Email" formControlName="mail">
        </div>
        <div class="form-group">
          <input type="password" class="form-control" id="password" placeholder="Mot de passe" formControlName="password">
        </div>
        <button type="submit" class="btn btn-info">Connexion</button>
      </div>
    </form>
  </div>