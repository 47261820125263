<div class="loading" *ngIf="showLoader">
  <div class="loader">
    <span class="dot dot_1"></span>
    <span class="dot dot_2"></span>
    <span class="dot dot_3"></span>
    <span class="dot dot_4"></span>
  </div>
</div>
<div class="content" *ngIf="!showLoader">
  <div class="form-row" id="weeklyDateForm">
    <div class="form-group col-md-6">
      <div class="dateArrows">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="previousWeek()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-success btn-sm" (click)="resetCalendarPosition()">
          <i class="fa fa-circle" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="nextWeek()">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="form-row" id="dayDateForm">
    <div class="form-group col-md-12">
      <div class="dateArrows">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="previousDay()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-success btn-sm" (click)="resetCalendarPosition()">
          <i class="fa fa-circle" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="nextDay()">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="form-group col-md-6">
    </div>
  </div>
  <div class="modal fade show" *ngIf="showPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <span style="float:left;font-size: 30px;color:#3097D1">
              {{ appointment.startDate | date: "dd-MM-yyyy" }}</span>
            <br>
            <span style="float:left;font-size: 30px;color:#3097D1">
              {{ appointment.startDate | date: "HH:mm" }} à {{ appointment.endDate | date: "HH:mm" }}</span>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="color:#dc3545"><b>{{appointment.highlightedRemark}}</b></p>
          <p><strong>{{appointment.client.lastname}}</strong></p>
          <p *ngIf="appointment.client.firstname">{{appointment.client.firstname}}</p>
          <hr>
          <p>{{appointment.address.street}} - {{appointment.address.postalCode}} - {{appointment.address.city}}</p>
          <hr *ngIf="appointment.client.mobileNumber">
          <a href="tel:{{appointment.client.mobileNumber}}"
            *ngIf="appointment.client.mobileNumber">{{appointment.client.mobileNumber}}</a>
          <hr *ngIf="appointment.client.fixNumber">
          <a href="tel:{{appointment.client.fixNumber}}"
            *ngIf="appointment.client.fixNumber">{{appointment.client.fixNumber}}</a>
          <div class="form-row">
            <div class="form-group col-md-6">
              <hr>
              <strong>Fichier PDF</strong>
            </div>
            <div class="form-group col-md-6" *ngIf="appointment.file">
              <hr>
              <a href="{{appointment.file.url}}" target="_blank">{{appointment.file.name}}</a>
            </div>
            <div class="form-group col-md-6" *ngIf="!appointment.file">
              <hr>
              <span>Pas de Fichier</span>
            </div>
            <hr>
          </div>
          <hr *ngIf="appointment.remark">
          <p *ngIf="appointment.remark" [innerHTML]="appointment.remark"></p>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-bordered" id="dayTable">
    <thead>
      <tr>
        <th></th>
        <th>
          <p style="font-weight:300">{{currentDate | date: 'dd-MM-yyyy'}}</p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="tableRow" *ngFor="let item of mobileTable">
        <td style="padding-left:5px;">{{item.time}}</td>
        <ng-container *ngFor="let subItem of item.dates; let i = index">
          <td style="position: relative;height: 25px;" (click)="openPopup(subItem.date, subItem.appointment)">
            <div *ngIf="subItem.appointment" [ngClass]="agenda" [style.height.px]="subItem.appointment.size">
              <span class="calendarAppointment">
                <span>{{subItem.appointment.startDate | date : 'HH:mm'}}</span><br>
                <span>{{subItem.appointment.endDate | date : 'HH:mm'}}</span><br>
                <strong>{{subItem.appointment.client.lastname}}</strong> {{subItem.appointment.client.firstname}}
              </span>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <table class="table table-bordered" id="weeklyTable">
    <thead>
      <tr>
        <th style="width: 5%"></th>
        <th style="width: 13%">
          <p>Lundi</p>
          <p style="font-weight:300">{{ monday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="mondayRemark && mondayRemark.remark">
            {{mondayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!mondayRemark || !mondayRemark.remark">Pas de
            remarque</p>
        </th>
        <th style="width: 13%">
          <p>Mardi</p>
          <p style="font-weight:300">{{ tuesday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="tuesdayRemark && tuesdayRemark.remark">
            {{tuesdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!tuesdayRemark || !tuesdayRemark.remark">Pas
            de remarque</p>
        </th>
        <th style="width: 13%">
          <p>Mercredi</p>
          <p style="font-weight:300">{{ wednesday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="wednesdayRemark && wednesdayRemark.remark">
            {{wednesdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!wednesdayRemark || !wednesdayRemark.remark">
            Pas de remarque</p>
        </th>
        <th style="width: 13%">
          <p>Jeudi</p>
          <p style="font-weight:300">{{ thursday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="thursdayRemark && thursdayRemark.remark">
            {{thursdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!thursdayRemark || !thursdayRemark.remark">Pas
            de remarque</p>
        </th>
        <th style="width: 13%">
          <p>Vendredi</p>
          <p style="font-weight:300">{{ friday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="fridayRemark && fridayRemark.remark">
            {{fridayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!fridayRemark || !fridayRemark.remark">Pas de
            remarque</p>
        </th>
        <th style="width: 13%">
          <p>Samedi</p>
          <p style="font-weight:300">{{ saturday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="saturdayRemark && saturdayRemark.remark">
            {{saturdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!saturdayRemark || !saturdayRemark.remark">Pas
            de remarque</p>
        </th>
        <th style="width: 13%">
          <p>Dimanche</p>
          <p style="font-weight:300">{{ sunday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" *ngIf="sundayRemark && sundayRemark.remark">
            {{sundayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" *ngIf="!sundayRemark || !sundayRemark.remark">Pas de
            remarque</p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="tableRow" *ngFor="let item of table">
        <td style="text-align: center">{{ item.time }}</td>
        <ng-container *ngFor="let subItem of item.dates; let i = index">
          <td style="position: relative;height: 25px;" (click)="openPopup(subItem.date, subItem.appointment)">
            <div *ngIf="subItem.appointment" class="agenda" [style.height.px]="subItem.appointment.size">
              <span class="calendarAppointment">
                <span>{{subItem.appointment.startDate | date : 'HH:mm'}}</span><br>
                <span>{{subItem.appointment.endDate | date : 'HH:mm'}}</span><br>
                <strong>{{subItem.appointment.client.lastname}}</strong> {{subItem.appointment.client.firstname}}
              </span>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
