<div class="loading" *ngIf="showLoader">
  <div class="loader">
    <span class="dot dot_1"></span>
    <span class="dot dot_2"></span>
    <span class="dot dot_3"></span>
    <span class="dot dot_4"></span>
  </div>
</div>
<div class="content" *ngIf="!showLoader">
  <div class="form-row" id="weeklyDateForm">
    <div class="form-group col-md-6">
      <div class="dateArrows">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="previousWeek()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-success btn-sm" (click)="resetCalendarPosition()">
          <i class="fa fa-circle" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="nextWeek()">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="form-group col-md-6">
      <form [formGroup]="dateForm" (submit)="dateFormSubmit(dateForm.value)">
        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
          <input type="tel" class="form-control" id="date" placeholder="Aller à cette date (jj-mm-aaaa)"
            [formControl]="dateForm.controls['date']" mask="00-00-0000" />
          <button class="btn btn-secondary" style="margin-left:2px;" type="submit">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="form-row" id="dayDateForm">
    <div class="form-group col-md-12">
      <div class="dateArrows">
        <button type="button" class="btn btn-outline-info btn-sm" (click)="previousDay()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-success btn-sm" (click)="resetCalendarPosition()">
          <i class="fa fa-circle" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="nextDay()">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="form-group col-md-6">
      <form [formGroup]="dateForm" (submit)="dateFormSubmitMobile(dateForm.value)">
        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
          <input type="tel" class="form-control" id="date" placeholder="Aller à cette date (jj-mm-aaaa)"
            [formControl]="dateForm.controls['date']" mask='99-99-9999'>
          <button class="btn btn-secondary" style="margin-left:2px;" type="submit">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="modal fade show" *ngIf="showAddPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div *ngIf="popupConsultMode">
            <span style="float:left;font-size: 30px;color:#3097D1">
              {{ appointment.startDate | date: "dd-MM-yyyy" }}</span>
            <br>
            <span style="float:left;font-size: 30px;color:#3097D1">
              {{ appointment.startDate | date: "HH:mm" }} à {{ appointment.endDate | date: "HH:mm" }}</span>
          </div>
          <!-- <div *ngIf="!popupConsultMode">
            <span style="float:left;font-size: 30px;color:#3097D1">{{
                    appointment.startDate | date: "dd-MM-yyyy à HH:mm"
                }}</span>
          </div> -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="popupConsultMode">
            <p style="color:#dc3545"><b>{{appointment.highlightedRemark}}</b></p>
            <p><strong>{{appointment.client.lastname}}</strong></p>
            <p *ngIf="appointment.client.firstname">{{appointment.client.firstname}}</p>
            <hr>
            <p>{{appointment.address.street}} - {{appointment.address.postalCode}} - {{appointment.address.city}}</p>
            <hr *ngIf="appointment.client.mobileNumber">
            <a href="tel:{{appointment.client.mobileNumber}}"
              *ngIf="appointment.client.mobileNumber">{{appointment.client.mobileNumber}}</a>
            <hr *ngIf="appointment.client.fixNumber">
            <a href="tel:{{appointment.client.fixNumber}}"
              *ngIf="appointment.client.fixNumber">{{appointment.client.fixNumber}}</a>
              <hr>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="appointment.isBilled" (change)="changeIsBilled(appointment)">
              <label class="form-check-label" for="exampleCheck1">A été facturé</label>
            </div>
            <br />
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck2" [(ngModel)]="appointment.receipt" (change)="changeReceipt(appointment)">
              <label class="form-check-label" for="exampleCheck2">Reçu</label>
            </div>
            <br />
            <div class="form-row">
              <div class="form-group col-md-6">
                <hr>
                <strong>Fichier PDF</strong>
              </div>
              <div class="form-group col-md-6" *ngIf="appointment.file">
                <hr>
                <a href="{{appointment.file.url}}" target="_blank">{{appointment.file.name}}</a>
              </div>
              <div class="form-group col-md-6" *ngIf="!appointment.file">
                <hr>
                <span>Pas de Fichier</span>
              </div>
              <hr>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <hr>
                <strong>Image 1</strong>
              </div>
              <div class="form-group col-md-6" *ngIf="appointment.image1">
                <hr>
                <a href="{{appointment.image1.url}}" target="_blank">{{appointment.image1.name}}</a>
              </div>
              <div class="form-group col-md-6" *ngIf="!appointment.image1">
                <hr>
                <span>Pas de Fichier</span>
              </div>
              <hr>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <hr>
                <strong>Image 2</strong>
              </div>
              <div class="form-group col-md-6" *ngIf="appointment.image2">
                <hr>
                <a href="{{appointment.image2.url}}" target="_blank">{{appointment.image2.name}}</a>
              </div>
              <div class="form-group col-md-6" *ngIf="!appointment.image2">
                <hr>
                <span>Pas de Fichier</span>
              </div>
              <hr>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <hr>
                <strong>Image 3</strong>
              </div>
              <div class="form-group col-md-6" *ngIf="appointment.image3">
                <hr>
                <a href="{{appointment.image3.url}}" target="_blank">{{appointment.image3.name}}</a>
              </div>
              <div class="form-group col-md-6" *ngIf="!appointment.image3">
                <hr>
                <span>Pas de Fichier</span>
              </div>
              <hr>
            </div>
            <hr *ngIf="appointment.remark">
            <p *ngIf="appointment.remark" [innerHTML]="appointment.remark | safeHtml"></p>
          </div>
          <div *ngIf="!popupConsultMode">
            <div class="alert alert-danger" role="alert" *ngIf="formError">
              Il y à une erreur !
            </div>
            <form [formGroup]="appointementForm" autocomplete="off">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="lastname">Client</label>
                  <input type="text" class="form-control" id="name" (input)="onValueChange($event.target.value)"
                    formControlName="name">
                  <div class="list-group" style="padding-top:10px;">
                    <a class="list-group-item list-group-item" style="cursor : pointer"
                      *ngFor="let client of searchResult" (click)="selectClient(client)">{{client.lastname}}
                      {{client.firstname}}</a>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="appointment.client">
                <div class="form-group col-md-12">
                  <label for="lastname">Adresse</label>
                  <div class="list-group" style="padding-top:10px;">
                    <a class="list-group-item"
                      [ngClass]="(address.id == appointment.address.id) ? 'list-group-item-success' : ''"
                      style="cursor : pointer" *ngFor="let address of appointment.client.addresses"
                      (click)="selectAddress(address)">
                      {{address.street}} {{address.city}}</a>
                  </div>
                </div>
              </div>
              <label>Date de début</label>
              <div class="form-row">
                <div class="form-group col-md-8">
                  <input type="text" class="form-control" id="startDate" formControlName="startDate" mask="00-00-0000"
                    (ngModelChange)="startDateChange($event)">
                </div>
                <div class="form-group col-md-2">
                  <select class="form-control" id="hours" formControlName="startHours">
                    <option *ngFor="let hour of hours" [ngValue]="hour">{{hour}}</option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <select class="form-control" id="minutes" formControlName="startMinutes">
                    <option *ngFor="let minute of minutes" [ngValue]="minute">{{minute}}</option>
                  </select>
                </div>
              </div>
              <label>Date de fin</label>
              <div class="form-row">
                <div class="form-group col-md-8">
                  <input type="text" class="form-control" id="endDate" formControlName="endDate" mask="00-00-0000">
                </div>
                <div class="form-group col-md-2">
                  <select class="form-control" id="hours" formControlName="endHours">
                    <option *ngFor="let hour of hours" [ngValue]="hour">{{hour}}</option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <select class="form-control" id="minutes" formControlName="endMinutes">
                    <option *ngFor="let minute of minutes" [ngValue]="minute">{{minute}}</option>
                  </select>
                </div>
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="isBilled">
                <label class="form-check-label" for="exampleCheck1">A été facturé</label>
              </div>
              <br />
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck2" formControlName="receipt">
                <label class="form-check-label" for="exampleCheck2">Reçu</label>
              </div>
              <br />
              <div class="form-row" *ngIf="!appointment.file">
                <div class="form-group col-md-12">
                  <label>Fichier PDF</label>
                  <br>
                  <label class="btn btn-default">
                    <input type="file" (change)="selectFile($event)" accept="application/pdf">
                  </label>
                </div>
              </div>
              <div class="form-row" *ngIf="!appointment.image1">
                <div class="form-group col-md-12">
                  <label>Image 1</label>
                  <br>
                  <label class="btn btn-default">
                    <input type="file" (change)="selectImage1($event)" accept="image/*">
                  </label>
                </div>
              </div>
              <div class="form-row" *ngIf="!appointment.image2">
                <div class="form-group col-md-12">
                  <label>Image 2</label>
                  <br>
                  <label class="btn btn-default">
                    <input type="file" (change)="selectImage2($event)" accept="image/*">
                  </label>
                </div>
              </div>
              <div class="form-row" *ngIf="!appointment.image3">
                <div class="form-group col-md-12">
                  <label>Image 3</label>
                  <br>
                  <label class="btn btn-default">
                    <input type="file" (change)="selectImage3($event)" accept="image/*">
                  </label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="highlightedRemark">Remarque importante</label>
                  <input type="text" class="form-control" id="highlightedRemark" formControlName="highlightedRemark">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="remark">Remarque</label>
                  <textarea class="form-control" id="remark" [froalaEditor]='options' formControlName="remark" rows="5"
                    style="resize: none;"></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div *ngIf="popupConsultMode">
            <button type="button" class="btn btn-outline-info" *ngIf="!confirmDelete" style="margin-right: 10px;"
              (click)="editAppointment(appointment)">Modifier</button>
            <button type="button" class="btn btn-outline-danger" *ngIf="!confirmDelete"
              (click)="confirmDelete = true">Supprimer</button>
            <button type="button" class="btn btn-outline-success" *ngIf="confirmDelete" style="margin-right: 10px;"
              (click)="deleteAppointment(appointment)">Confirmer</button>
            <button type="button" class="btn btn-outline-warning" *ngIf="confirmDelete"
              (click)="confirmDelete = false">Annuler</button>
          </div>
          <div *ngIf="!popupConsultMode">
            <button type="button" class="btn btn-outline-success" (click)="submitForm()">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-bordered" id="dayTable">
    <thead>
      <tr>
        <th></th>
        <th>
          <p style="font-weight:300">{{currentDate | date: 'dd-MM-yyyy'}}</p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="tableRow" *ngFor="let item of mobileTable">
        <td style="padding-left:5px;">{{item.time}}</td>
        <ng-container *ngFor="let subItem of item.dates; let i = index">
          <td style="position: relative;height: 25px;" (click)="openPopup(subItem.date, subItem.appointment)">
            <div *ngIf="subItem.appointment" [ngClass]="agenda.id" [style.height.px]="subItem.appointment.size">
              <span class="calendarAppointment">
                <span>{{subItem.appointment.startDate | date : 'HH:mm'}}</span><br>
                <span>{{subItem.appointment.endDate | date : 'HH:mm'}}</span><br>
                <i *ngIf="subItem.appointment.isBilled" class="fa fa-check" aria-hidden="true" style="margin-right: 10px;"></i><strong>{{subItem.appointment.client.lastname}}</strong> {{subItem.appointment.client.firstname}}
              </span>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <table class="table table-bordered" id="weeklyTable">
    <thead>
      <tr>
        <th style="width: 5%"></th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Lundi</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(monday)"></i></div>
          </div>
          <p style="font-weight:300">{{ monday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(monday, mondayRemark)"
            *ngIf="mondayRemark && mondayRemark.remark">{{mondayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(monday, mondayRemark)"
            *ngIf="!mondayRemark || !mondayRemark.remark">Ajouter une remarque</p>
        </th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Mardi</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(tuesday)"></i></div>
          </div>
          <p style="font-weight:300">{{ tuesday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(tuesday, tuesdayRemark)"
            *ngIf="tuesdayRemark && tuesdayRemark.remark">{{tuesdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(tuesday, tuesdayRemark)"
            *ngIf="!tuesdayRemark || !tuesdayRemark.remark">Ajouter une remarque</p>
        </th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Mercredi</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(wednesday)"></i></div>
          </div>
          <p style="font-weight:300">{{ wednesday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(wednesday, wednesdayRemark)"
            *ngIf="wednesdayRemark && wednesdayRemark.remark">{{wednesdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(wednesday, wednesdayRemark)"
            *ngIf="!wednesdayRemark || !wednesdayRemark.remark">Ajouter une remarque</p>
        </th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Jeudi</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(thursday)"></i></div>
          </div>
          <p style="font-weight:300">{{ thursday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(thursday, thursdayRemark)"
            *ngIf="thursdayRemark && thursdayRemark.remark">{{thursdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(thursday, thursdayRemark)"
            *ngIf="!thursdayRemark || !thursdayRemark.remark">Ajouter une remarque</p>
        </th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Vendredi</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(friday)"></i></div>
          </div>
          <p style="font-weight:300">{{ friday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(friday, fridayRemark)"
            *ngIf="fridayRemark && fridayRemark.remark">{{fridayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(friday, fridayRemark)"
            *ngIf="!fridayRemark || !fridayRemark.remark">Ajouter une remarque</p>
        </th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Samedi</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(saturday)"></i></div>
          </div>
          <p style="font-weight:300">{{ saturday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(saturday, saturdayRemark)"
            *ngIf="saturdayRemark && saturdayRemark.remark">{{saturdayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(saturday, saturdayRemark)"
            *ngIf="!saturdayRemark || !saturdayRemark.remark">Ajouter une remarque</p>
        </th>
        <th style="width: 13%">
          <div class="row">
            <div class="col-md-9">
              <p>Dimanche</p>
            </div>
            <div class="col-md-3"><i class="fa fa-print" (click)="print(sunday)"></i></div>
          </div>
          <p style="font-weight:300">{{ sunday | date: "dd-MM-yyyy" }}</p>
          <p style="font-weight:300;cursor: pointer;color:#28a745" (click)="showRemark(sunday, sundayRemark)"
            *ngIf="sundayRemark && sundayRemark.remark">{{sundayRemark.remark}}</p>
          <p style="font-weight:300;cursor: pointer;color:#6c757d" (click)="showRemark(sunday, sundayRemark)"
            *ngIf="!sundayRemark || !sundayRemark.remark">Ajouter une remarque</p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="tableRow" *ngFor="let item of table">
        <td style="text-align: center">{{ item.time }}</td>
        <ng-container *ngFor="let subItem of item.dates; let i = index">
          <td style="position: relative;height: 25px;" (click)="openPopup(subItem.date, subItem.appointment)">
            <div *ngIf="subItem.appointment" [ngClass]="agenda.id" [style.height.px]="subItem.appointment.size">
              
              <span class="calendarAppointment">
                <span>{{subItem.appointment.startDate | date : 'HH:mm'}}</span><br>
                <span>{{subItem.appointment.endDate | date : 'HH:mm'}}</span><br>
                <i *ngIf="subItem.appointment.isBilled" class="fa fa-check" aria-hidden="true" style="margin-right: 10px;"></i><strong>{{subItem.appointment.client.lastname}}</strong> {{subItem.appointment.client.firstname}}
              </span>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <div class="modal" tabindex="-1" role="dialog" *ngIf="showRemarkPopup">
    <div class="modal-dialog" role="document">
      <form [formGroup]="remarkForm" (submit)="submitRemarkForm(remarkForm.value)">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ajouter une remarque</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="showRemarkPopup = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <textarea class="form-control" id="remark" rows="3" formControlName="remark"></textarea>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success" [disabled]="!remarkForm.valid">Enregistrer</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>