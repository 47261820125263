import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Client } from '../../../models/models';
import { Address } from '../../../models/models';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
  options: Object = {
    key: 'RA-7sA-31B-22d1zE-11vA-8I2vA-21sD-16xesD3i1kh==',
    language: 'fr',
    height: '300',
    placeholderText: '',
    quickInsertButtons: [],
    charCounterCount: true,
    toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'color', '|', 'formatOL', 'formatUL', 'insertTable', 'insertFile', 'insertImage', 'insertVideo', 'insertLink', 'html'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    colorsStep: 6,
    colorsText: [
      '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
      '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
    ]
  }
  showLoading = false;
  client: Client;
  clientAddresses = [];
  clientInterventions = [];
  clientDevis = [];
  currentTab = 'general';
  clientFormError = false;
  clientFormErrorMessage = "";
  clientForm: FormGroup = this.formBuilder.group({
    'id': [null],
    'firstname': [null],
    'lastname': [null, Validators.required],
    'mobileNumber': [null],
    'fixNumber': [null],
    'email': [null],
    'tva': [null],
    'remark': [null]
  });
  adressForm: FormGroup = this.formBuilder.group({
    'street': [null, Validators.required],
    'postalCode': [null],
    'city': [null, Validators.required]
  });;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore) {
    route.params.subscribe(params => {
      if (params.clientId) {
        this.showLoading = true;
        this.afs.doc<Client>('Clients/' + params.clientId).valueChanges().subscribe(data => {
          this.client = data;
          this.clientForm.controls['id'].setValue(this.client.id);
          this.clientForm.controls['lastname'].setValue(this.client.lastname);
          this.clientForm.controls['firstname'].setValue(this.client.firstname);
          this.clientForm.controls['mobileNumber'].setValue(this.client.mobileNumber);
          this.clientForm.controls['fixNumber'].setValue(this.client.fixNumber);
          this.clientForm.controls['email'].setValue(this.client.email);
          this.clientForm.controls['tva'].setValue(this.client.tva);
          this.clientForm.controls['remark'].setValue(this.client.remark);
          this.clientAddresses = this.client.addresses;
          this.clientInterventions = this.client.interventions;
          this.clientDevis = this.client.devis;
          this.showLoading = false;
        });
      }
    });
  }

  ngOnInit() {

  }

  submitAddressForm(value: any): void {
    var defaultFacturation = false;
    if (this.clientAddresses.length == 0) {
      defaultFacturation = true;
    }
    this.clientAddresses.push({
      'id': this.afs.createId(),
      'street': value.street,
      'postalCode': value.postalCode,
      'city': value.city,
      'facturation': defaultFacturation
    });
    this.adressForm.reset();
    if (this.client) {
      this.afs.doc<Client>('Clients/' + this.client.id).update({ 'addresses': this.clientAddresses });
    }
  }

  setFacturationAddress(value: any): void {
    if (this.clientAddresses.length == 1) {
      this.clientAddresses[0].facturation = true;
    } else {
      var oldIndex = this.clientAddresses.findIndex(x => x.facturation == true && x.id != value);
      this.clientAddresses[oldIndex].facturation = false;
    }
  }

  removeAddress(value: any): void {
    var index = this.clientAddresses.indexOf(value);
    this.clientAddresses.splice(index, 1);
    if (this.clientAddresses.length == 1) {
      this.clientAddresses[0].facturation = true;
    }
    if (this.client) {
      this.afs.doc<Client>('Clients/' + this.client.id).update({ 'addresses': this.clientAddresses });
    }
  }

  submitClientForm(value: any): void {
    if (!value.lastname) {
      this.clientFormError = true;
      this.clientFormErrorMessage = "Le nom doit être renseigné";
    } else {
      if (this.clientAddresses.length == 0) {
        this.clientFormError = true;
        this.clientFormErrorMessage = "Au moins une adrese doit être renseignée";
      } else {
        this.showLoading = true;
        this.clientFormError = false;
        this.clientFormErrorMessage = "";
        var client: Client = {
          id: value.id ? value.id : this.afs.createId(),
          lastname: value.lastname,
          firstname: value.firstname,
          mobileNumber: value.mobileNumber,
          fixNumber: value.fixNumber,
          email: value.email,
          tva: value.tva,
          remark: value.remark ? value.remark.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '',
          addresses: this.clientAddresses,
          interventions: this.clientInterventions,
          devis: this.clientDevis
        };
        this.clientAddresses.forEach(address => {
          if (!client.addresses.some(x => x.id == address.id)) {
            var mappedAddersse: Address = {
              id: address.id,
              street: address.street,
              postalCode: address.postalCode,
              city: address.city,
              facturation: address.facturation
            }
            client.addresses.push(mappedAddersse);
          }
        });
        if (this.client) {
          this.afs.doc<Client>('Clients/' + client.id).update(client)
            .then(() => {
              this.router.navigate(['/client/' + client.id]);
            })
            .catch((error) => {
              this.showLoading = false;
              this.clientFormError = true;
              this.clientFormErrorMessage = "Une erreur s'est produite : " + error;
            });
        } else {
          this.afs.collection<Client>('Clients').doc(client.id).set(client).then(() => {
            this.router.navigate(['/client/' + client.id]);
          }).catch((error) => {
            this.showLoading = false;
            this.clientFormError = true;
            this.clientFormErrorMessage = "Une erreur s'est produite : " + error;
          }); 
        }
      }
    }
  }

  close(): void {
    if (this.client) {
      this.router.navigate(['/client/' + this.client.id]);
    } else {
      this.router.navigate(['/clients']);
    }
  }
}
