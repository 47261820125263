import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Client, PdfFile, Devis, FileUpload } from '../../../../models/models';

@Component({
  selector: 'app-edit-devis',
  templateUrl: './edit-devis.component.html',
  styleUrls: ['./edit-devis.component.css']
})
export class EditDevisComponent implements OnInit {
  options: Object = {
    key: 'RA-7sA-31B-22d1zE-11vA-8I2vA-21sD-16xesD3i1kh==',
    language: 'fr',
    height: '300',
    placeholderText: '',
    quickInsertButtons: [],
    charCounterCount: true,
    toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'color', '|', 'formatOL', 'formatUL', 'insertTable', 'insertFile', 'insertImage', 'insertVideo', 'insertLink', 'html'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    colorsStep: 6,
    colorsText: [
      '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
      '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
    ]
  }
  client: Client;
  devis: Devis;
  showLoading = false;
  devisFormError = false;
  devisFormErrorMessage = "";
  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  devisForm: FormGroup = this.formBuilder.group({
    'id': [null],
    'date': [null, Validators.required],
    'title': [null, Validators.required],
    'description': [null]
  });;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore
  ) {
    this.showLoading = true;
    route.params.subscribe(params => {
      if (!params.clientId) {
        this.router.navigateByUrl('/clients');
      } else {
        this.afs.doc<Client>('Clients/' + params.clientId).valueChanges().subscribe(data => {
          this.client = data;
          if (params.devisId) {
            this.devis = this.client.devis.find(x => x.id == params.devisId);
            if(this.devis){
              this.devisForm.controls['id'].setValue(this.devis.id);
              this.devisForm.controls['date'].setValue(this.devis.date);
              this.devisForm.controls['title'].setValue(this.devis.title);
              this.devisForm.controls['description'].setValue(this.devis.description);
            }
          }
          this.showLoading = false;
        });
      }
    });
  }

  ngOnInit() {
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  submitDevisForm(value: any): void {
    this.showLoading = true;
    if (this.selectedFiles && this.selectedFiles.item(0)) {
      var file = this.selectedFiles.item(0);
      this.currentFileUpload = new FileUpload(file);
      var storageRef = firebase.storage().ref();
      var basePath = 'devis';
      storageRef.child(`${basePath}/${this.currentFileUpload.file.name}`).put(this.currentFileUpload.file).then((data) => {
        storageRef.child(`${basePath}/${this.currentFileUpload.file.name}`).getDownloadURL().then((url) => {
        var pdfFile: PdfFile = {
          name: this.currentFileUpload.file.name,
          url: url
        }
        if (this.devis) {
          this.devis.title = value.title;
          this.devis.date = value.date;
          this.devis.file = pdfFile;
          var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
          this.devis.description = description;
          var index = this.client.devis.findIndex(x => x.id == this.devis.id);
          this.client.devis.splice(index, 1);
          this.client.devis.push(this.devis);
          this.afs.doc('Clients/' + this.client.id).update({ 'devis': this.client.devis }).then(() => {
            this.router.navigateByUrl('/devis/' + this.client.id + '/' + this.devis.id);
          })
            .catch((error) => {
              this.showLoading = false;
              this.devisFormError = true;
              this.devisFormErrorMessage = "Une erreur s'est produite : " + error;
            });
        } else {
          var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
          var devis: Devis = {
            id: this.afs.createId(),
            title: value.title,
            date: value.date,
            file: pdfFile,
            description: description
          };
          this.client.devis.push(devis);
          this.afs.doc('Clients/' + this.client.id).update({ 'devis': this.client.devis }).then(() => {
            this.router.navigateByUrl('/devis/' + this.client.id + '/' + devis.id);
          })
            .catch((error) => {
              this.showLoading = false;
              this.devisFormError = true;
              this.devisFormErrorMessage = "Une erreur s'est produite : " + error;
            });
        }
      });
      });
    } else {
      if (this.devis) {
        this.devis.title = value.title;
        this.devis.date = value.date;
        var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
        this.devis.description = description;
        var index = this.client.devis.findIndex(x => x.id == this.devis.id);
        this.client.devis.splice(index, 1);
        this.client.devis.push(this.devis);
        this.afs.doc('Clients/' + this.client.id).update({ 'devis': this.client.devis }).then(() => {
          this.router.navigateByUrl('/devis/' + this.client.id + '/' + this.devis.id);
        })
          .catch((error) => {
            this.showLoading = false;
            this.devisFormError = true;
            this.devisFormErrorMessage = "Une erreur s'est produite : " + error;
          });
      } else {
        var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
        var devis: Devis = {
          id: this.afs.createId(),
          title: value.title,
          date: value.date,
          file: null,
          description: description
        };
        this.client.devis.push(devis);
        this.afs.doc('Clients/' + this.client.id).update({ 'devis': this.client.devis }).then(() => {
          this.router.navigateByUrl('/devis/' + this.client.id + '/' + devis.id);
        })
          .catch((error) => {
            this.showLoading = false;
            this.devisFormError = true;
            this.devisFormErrorMessage = "Une erreur s'est produite : " + error;
          });
      }
    }
  }

  close(): void {
    if (this.devis) {
      this.router.navigateByUrl('/devis/' + this.client.id + '/' + this.devis.id);
    } else {
      this.router.navigateByUrl('/client/' + this.client.id);
    }
  }
}
