import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Client, PdfFile, Intervention, FileUpload } from '../../../../models/models';

@Component({
  selector: 'app-edit-intervention',
  templateUrl: './edit-intervention.component.html',
  styleUrls: ['./edit-intervention.component.css']
})
export class EditInterventionComponent implements OnInit {
  options: Object = {
    key: 'RA-7sA-31B-22d1zE-11vA-8I2vA-21sD-16xesD3i1kh==',
    language: 'fr',
    height: '300',
    placeholderText: '',
    quickInsertButtons: [],
    charCounterCount: true,
    toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'color', '|', 'formatOL', 'formatUL', 'insertTable', 'insertFile', 'insertImage', 'insertVideo', 'insertLink', 'html'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
    colorsStep: 6,
    colorsText: [
      '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
      '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
    ]
  }
  client: Client;
  intervention: Intervention;
  showLoading = false;
  interventionFormError = false;
  interventionFormErrorMessage = "";
  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  interventionForm: FormGroup = this.formBuilder.group({
    'id': [null],
    'date': [null, Validators.required],
    'title': [null, Validators.required],
    'description': [null]
  });

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore
  ) {
    this.showLoading = true;
    route.params.subscribe(params => {
      if (!params.clientId) {
        this.router.navigateByUrl('/clients');
      } else {
        this.afs.doc<Client>('Clients/' + params.clientId).valueChanges().subscribe(data => {
          this.client = data;
          if (params.interventionId) {
            this.intervention = this.client.interventions.find(x => x.id == params.interventionId);
            if(this.intervention){
              this.interventionForm.controls['id'].setValue(this.intervention.id);
              this.interventionForm.controls['date'].setValue(this.intervention.date);
              this.interventionForm.controls['title'].setValue(this.intervention.title);
              this.interventionForm.controls['description'].setValue(this.intervention.description);
            }
          }
          this.showLoading = false;
        });
      }
    });
  }

  ngOnInit() {
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  submitInterventionForm(value: any): void {
    this.showLoading = true;
    if (this.selectedFiles && this.selectedFiles.item(0)) {
      var file = this.selectedFiles.item(0);
      this.currentFileUpload = new FileUpload(file);
      var storageRef = firebase.storage().ref();
      var basePath = 'factures';
      storageRef.child(`${basePath}/${this.currentFileUpload.file.name}`).put(this.currentFileUpload.file).then((data) => {
        storageRef.child(`${basePath}/${this.currentFileUpload.file.name}`).getDownloadURL().then((url) => {
          var pdfFile: PdfFile = {
            name: this.currentFileUpload.file.name,
            url: url
          }
          if (this.intervention) {
            this.intervention.title = value.title;
            this.intervention.date = value.date;
            this.intervention.file = pdfFile;
            var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
            this.intervention.description = description;
            var index = this.client.interventions.findIndex(x => x.id == this.intervention.id);
            this.client.interventions.splice(index, 1);
            this.client.interventions.push(this.intervention);
            this.afs.doc('Clients/' + this.client.id).update({ 'interventions': this.client.interventions }).then(() => {
              this.router.navigateByUrl('/intervention/' + this.client.id + '/' + this.intervention.id);
            })
              .catch((error) => {
                this.showLoading = false;
                this.interventionFormError = true;
                this.interventionFormErrorMessage = "Une erreur s'est produite : " + error;
              });
          } else {
            var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
            var intervention: Intervention = {
              id: this.afs.createId(),
              title: value.title,
              date: value.date,
              file: pdfFile,
              description: description
            };
            this.client.interventions.push(intervention);
            this.afs.doc('Clients/' + this.client.id).update({ 'interventions': this.client.interventions }).then(() => {
              this.router.navigateByUrl('/intervention/' + this.client.id + '/' + intervention.id);
            })
              .catch((error) => {
                this.showLoading = false;
                this.interventionFormError = true;
                this.interventionFormErrorMessage = "Une erreur s'est produite : " + error;
              });
          }
        });
      });
    } else {
      if (this.intervention) {
        this.intervention.title = value.title;
        this.intervention.date = value.date;
        var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
        this.intervention.description = description;
        var index = this.client.interventions.findIndex(x => x.id == this.intervention.id);
        this.client.interventions.splice(index, 1);
        this.client.interventions.push(this.intervention);
        this.afs.doc('Clients/' + this.client.id).update({ 'interventions': this.client.interventions }).then(() => {
          this.router.navigateByUrl('/intervention/' + this.client.id + '/' + this.intervention.id);
        })
          .catch((error) => {
            this.showLoading = false;
            this.interventionFormError = true;
            this.interventionFormErrorMessage = "Une erreur s'est produite : " + error;
          });
      } else {
        var description = value.description ? value.description.replace('<table style="width: 100%;">', '<table style="width: 100%;" class="table">') : '';
        var intervention: Intervention = {
          id: this.afs.createId(),
          title: value.title,
          date: value.date,
          file: null,
          description: description
        };
        this.client.interventions.push(intervention);
        this.afs.doc('Clients/' + this.client.id).update({ 'interventions': this.client.interventions }).then(() => {
          this.router.navigateByUrl('/intervention/' + this.client.id + '/' + intervention.id);
        })
          .catch((error) => {
            this.showLoading = false;
            this.interventionFormError = true;
            this.interventionFormErrorMessage = "Une erreur s'est produite : " + error;
          });
      }
    }
  }

  close(): void {
    if (this.intervention) {
      this.router.navigateByUrl('/intervention/' + this.client.id + '/' + this.intervention.id);
    } else {
      this.router.navigateByUrl('/client/' + this.client.id);
    }
  }
}
