import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Team } from './models/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Lamouline Manager';
  isAmdin = false;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    afAuth.onAuthStateChanged((user) => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        this.afs
          .collection<Team>('Teams', (ref) =>
            ref.where('email', '==', user.email)
          )
          .valueChanges()
          .subscribe((data) => {
            if (!data[0]) {
              this.isAmdin = true;
            } else {
              this.isAmdin = false;
            }
          });
      }
    });
  }
}
