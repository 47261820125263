import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGuard implements CanActivate {


  constructor(private app : AppComponent, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.app.isAmdin){
      return true;
    }else{
      this.router.navigate(['/agendas']);
      return false;
    }
  }
}
