import { Component, OnInit, TestabilityRegistry } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Client, Intervention } from '../../../models/models';

@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.css']
})
export class InterventionComponent implements OnInit {
  showLoading = false;
  error = false;
  errorMessage = '';
  showConfirmDelete = false;
  client : Client;
  intervention : Intervention;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore
  ) { 
    this.showLoading = true;
    route.params.subscribe(params => {
      this.afs.doc<Client>('Clients/' + params.clientId).valueChanges().subscribe(data => {
        this.client = data;
        this.intervention = data.interventions.find(x => x.id == params.interventionId);
        this.showLoading = false;
      });
    });
  }

  ngOnInit() {
  }

  editIntervention(){
    this.router.navigateByUrl('/editIntervention/' + this.client.id + '/' + this.intervention.id);
  }

  closeIntervention(){
    this.router.navigateByUrl('/client/' + this.client.id);
  }

  deleteIntervention(){
    this.showLoading = true;
    var index = this.client.interventions.findIndex(x => x.id == this.intervention.id);
    this.client.interventions.splice(index, 1);
    this.afs.doc<Client>('Clients/' + this.client.id).update({'interventions' : this.client.interventions}).then(() => {
      this.router.navigateByUrl('/client/' + this.client.id);
    })
    .catch((error) => {
      this.showLoading = false;
      this.error = true;
      this.errorMessage = "Une erreur s'est produite : " + error;
    });
  }

}
