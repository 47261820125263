<div class="modal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span style="float:left;font-size: 30px;color:#3097D1">Transférer vers ...</span>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopUp()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!selectedClient">
        <div class="form-row">
          <div class="form-group col-md-12">
            <input type="text" class="form-control" id="lastname" placeholder="Nom" (input)="onValueChange($event.target.value)" autocomplete="new-password" autocorrect="off" spellcheck="false">
            <div class="list-group" style="padding-top:10px;">
              <a class="list-group-item list-group-item" style="cursor : pointer" *ngFor="let client of searchResult" (click)="selectedClient = client">{{client.lastname}} {{client.firstname}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="selectedClient">
        <p>Transférer les données de <b>{{ client.lastname }} {{ client.firstname }}</b> vers <b>{{ selectedClient.lastname }} {{ selectedClient.firstname }}</b> ?</p>
      </div>
      <div class="modal-footer" *ngIf="selectedClient">
        <button type="button" class="btn btn-success" [disabled] = "!selectedClient" (click)="transfer()">Confirmer</button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="selectedClient = null; searchResult = []">Annuler</button>
      </div>
    </div>
  </div>
</div>