import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Agenda } from '../models/models';
import { Team } from '../models/models';

@Component({
  selector: 'app-agendas',
  templateUrl: './agendas.component.html',
  styleUrls: ['./agendas.component.css']
})
export class AgendasComponent implements OnInit {
  currentAgenda = 0;
  agenda: Agenda;
  agenda0: Agenda;
  agenda1: Agenda;
  agenda2: Agenda;
  agenda3: Agenda;
  agenda4: Agenda;
  agenda5: Agenda;
  agenda6: Agenda;
  agenda7: Agenda;
  agenda8: Agenda;
  agenda9: Agenda;
  agenda10: Agenda;

  constructor(public app: AppComponent, private afs: AngularFirestore, private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(
      data => {
        this.afs.collection<Team>("Teams", ref => ref.where("email", "==", data.email)).valueChanges().subscribe(data => {
          if(data[0]){
            this.agenda = {
              id: 'agenda' + data[0].id,
              team: data[0]
            }; 
          }else{
            this.agenda0 = {
              id: 'agenda0',
              team: null
            };
            this.afs.doc<Team>("/Teams/Team1").valueChanges().subscribe(data => {
              this.agenda1 = {
                id: 'agenda1',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team2").valueChanges().subscribe(data => {
              this.agenda2 = {
                id: 'agenda2',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team3").valueChanges().subscribe(data => {
              this.agenda3 = {
                id: 'agenda3',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team4").valueChanges().subscribe(data => {
              this.agenda4 = {
                id: 'agenda4',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team5").valueChanges().subscribe(data => {
              this.agenda5 = {
                id: 'agenda5',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team6").valueChanges().subscribe(data => {
              this.agenda6 = {
                id: 'agenda6',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team7").valueChanges().subscribe(data => {
              this.agenda7 = {
                id: 'agenda7',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team8").valueChanges().subscribe(data => {
              this.agenda8 = {
                id: 'agenda8',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team9").valueChanges().subscribe(data => {
              this.agenda9 = {
                id: 'agenda9',
                team: data
              };
            });
            this.afs.doc<Team>("/Teams/Team10").valueChanges().subscribe(data => {
              this.agenda10 = {
                id: 'agenda10',
                team: data
              };
            });
          }
          
        });
    });
  }
}
