import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {NgxMaskModule} from 'ngx-mask'
import * as $ from "jquery";
import * as docx from "docx";

window["$"] = $;
window["jQuery"] = $;
import "froala-editor/js/froala_editor.pkgd.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { AppComponent } from "./app.component";
import { OrderModule } from "ngx-order-pipe";
import { environment } from "./../environments/environment";
import { AppNavbarComponent } from "./app-navbar/app-navbar.component";
import { LoginComponent } from "./login/login.component";
import { ClientsComponent } from "./clients/clients.component";

import { ClientComponent } from "./clients/client/client.component";
import { EditClientComponent } from "./clients/client/edit-client/edit-client.component";
import { TransfertClientComponent } from "./clients/client//transfert-client/transfert-client.component";
import { InterventionComponent } from "./clients/client/intervention/intervention.component";
import { EditInterventionComponent } from "./clients/client/intervention/edit-intervention/edit-intervention.component";
import { DevisComponent } from "./clients/client/devis/devis.component";
import { EditDevisComponent } from "./clients/client/devis/edit-devis/edit-devis.component";
import { AgendasComponent } from './agendas/agendas.component';
import { AgendaComponent } from "./agendas/agenda/agenda.component";
import { TeamsComponent } from './teams/teams.component';
import { RoleGuard } from './guards/role-guard.service';
import { AgendaLockComponent } from './agendas/agenda-lock/agenda-lock.component';

import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "/agendas",
    pathMatch: "full"
  },
  { path: "login", component: LoginComponent },
  { path: "clients", component: ClientsComponent, canActivate: [RoleGuard] },
  { path: "client/:clientId", component: ClientComponent, canActivate: [RoleGuard] },
  { path: "newClient", component: EditClientComponent, canActivate: [RoleGuard] },
  { path: "editClient/:clientId", component: EditClientComponent, canActivate: [RoleGuard] },
  {
    path: "intervention/:clientId/:interventionId",
    component: InterventionComponent,canActivate: [RoleGuard]
  },
  { path: "addIntervention/:clientId", component: EditInterventionComponent, canActivate: [RoleGuard] },
  {
    path: "editIntervention/:clientId/:interventionId",
    component: EditInterventionComponent, canActivate: [RoleGuard]
  },
  { path: "devis/:clientId/:devisId", component: DevisComponent, canActivate: [RoleGuard] },
  { path: "addDevis/:clientId", component: EditDevisComponent, canActivate: [RoleGuard] },
  { path: "editDevis/:clientId/:devisId", component: EditDevisComponent, canActivate: [RoleGuard] },
  { path: "agendas", component: AgendasComponent },
  { path: "teams", component: TeamsComponent, canActivate: [RoleGuard] }
];

@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    LoginComponent,
    ClientsComponent,
    EditClientComponent,
    ClientComponent,
    EditInterventionComponent,
    InterventionComponent,
    EditDevisComponent,
    DevisComponent,
    TransfertClientComponent,
    AgendaComponent,
    AgendasComponent,
    TeamsComponent,
    AgendaLockComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    OrderModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [AppComponent, RoleGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
