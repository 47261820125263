import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Client } from '../../models/models';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})

export class ClientComponent implements OnInit {
  currentTab = 'general';
  showLoader = false;
  error = false;
  errorMessage = '';
  showConfirmDelete = false;
  client: Client;
  clients: Client[] = [];
  showTransfer = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore
  ) {

  }

  ngOnInit() {
    this.showLoader = true;
    this.route.params.subscribe(params => {
      this.afs.collection<Client>('Clients', ref => ref.orderBy('lastname')).valueChanges().subscribe(data => {
        this.clients = data;
        this.afs.doc<Client>('Clients/' + params.clientId).valueChanges().subscribe(data => {
          this.showLoader = false;
          this.client = data;
        });
      });
    });

  }

  editClient() {
    this.router.navigateByUrl('/editClient/' + this.client.id);
  }

  deleteClient() {
    this.afs.doc<Client>('Clients/' + this.client.id).delete().then(() => {
      this.router.navigate(['/clients']);
    })
      .catch((error) => {
        this.error = true;
        this.errorMessage = "Une erreur s'est produite : " + error;
      });
  }

  addIntervetion() {
    this.router.navigateByUrl('/addIntervention/' + this.client.id);
  }

  openIntervention(interventionId) {
    this.router.navigateByUrl('/intervention/' + this.client.id + '/' + interventionId);
  }

  addDevis() {
    this.router.navigateByUrl('/addDevis/' + this.client.id);
  }

  openDevis(devisId) {
    this.router.navigateByUrl('/devis/' + this.client.id + '/' + devisId);
  }

  closeClient() {
    this.router.navigateByUrl('/clients');
  }

  previousClient() {
    var index = this.clients.findIndex(x => x.id == this.client.id);
    if (this.clients[index - 1]) {
      this.router.navigateByUrl('/client/' + this.clients[index - 1].id);
    }
  }

  nextClient() {
    var index = this.clients.findIndex(x => x.id == this.client.id);
    if (this.clients[index + 1]) {
      this.router.navigateByUrl('/client/' + this.clients[index + 1].id);
    }
  }
}
