import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Client } from '../../../models/models';

@Component({
  selector: 'app-transfert-client',
  templateUrl: './transfert-client.component.html',
  styleUrls: ['./transfert-client.component.css']
})
export class TransfertClientComponent implements OnInit {
  clients: Client[];
  searchResult = [];
  selectedClient: Client = null;

  @Input()
  client: Client

  @Output()
  onClose = new EventEmitter();

  constructor(
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit() {
    this.afs.collection<Client>('Clients', ref => ref.orderBy('lastname')).valueChanges().subscribe(data => {
      this.clients = data;
    });
  }

  closePopUp() {
    this.onClose.emit();
  }

  onValueChange(value: string) {
    if (value) {
      this.searchResult = this.clients.filter(x => (x.lastname + ' ' + x.firstname).startsWith(value)).slice(0, 5);
    } else {
      this.searchResult = [];
    }
  }

  transfer() {
    this.selectedClient.addresses = this.selectedClient.addresses.concat(this.client.addresses);
    this.selectedClient.interventions = this.selectedClient.interventions.concat(this.client.interventions);
    this.selectedClient.devis = this.selectedClient.devis.concat(this.client.devis);
    this.afs.doc<Client>('Clients/' + this.selectedClient.id).update(this.selectedClient)
      .then(result => {
        this.onClose.emit();
        this.afs.doc<Client>('Clients/' + this.client.id).delete()
        .then(result => {
          this.router.navigate(['/client/' + this.selectedClient.id]);
        });
      });
  }
}
