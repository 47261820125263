<ul class="nav nav-tabs" *ngIf="agenda1 && agenda2 && agenda3 && agenda4 && agenda5 && agenda6 && agenda7 && agenda8 && agenda9 && agenda10 && app.isAmdin">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==0) ? 'active' : ''" (click)="currentAgenda = 0">
      Arnaud
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==1) ? 'active' : ''" (click)="currentAgenda = 1">
      {{agenda1.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==2) ? 'active' : ''" (click)="currentAgenda = 2">
      {{agenda2.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==3) ? 'active' : ''" (click)="currentAgenda = 3">
      {{agenda3.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==4) ? 'active' : ''" (click)="currentAgenda = 4">
      {{agenda4.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==5) ? 'active' : ''" (click)="currentAgenda = 5">
      {{agenda5.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==6) ? 'active' : ''" (click)="currentAgenda = 6">
      {{agenda6.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==7) ? 'active' : ''" (click)="currentAgenda = 7">
      {{agenda7.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==8) ? 'active' : ''" (click)="currentAgenda = 8">
      {{agenda8.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==9) ? 'active' : ''" (click)="currentAgenda = 9">
      {{agenda9.team.chief}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="(currentAgenda==10) ? 'active' : ''" (click)="currentAgenda = 10">
      {{agenda10.team.chief}}
    </a>
  </li>
</ul>
<div class="content"
  *ngIf="agenda1 && agenda2 && agenda3 && agenda4 && agenda5 && agenda6 && agenda7 && agenda8 && agenda9 && agenda10 && app.isAmdin">
  <app-agenda *ngIf="currentAgenda==0" [agenda]="agenda0"></app-agenda>
  <app-agenda *ngIf="currentAgenda==1" [agenda]="agenda1"></app-agenda>
  <app-agenda *ngIf="currentAgenda==2" [agenda]="agenda2"></app-agenda>
  <app-agenda *ngIf="currentAgenda==3" [agenda]="agenda3"></app-agenda>
  <app-agenda *ngIf="currentAgenda==4" [agenda]="agenda4"></app-agenda>
  <app-agenda *ngIf="currentAgenda==5" [agenda]="agenda5"></app-agenda>
  <app-agenda *ngIf="currentAgenda==6" [agenda]="agenda6"></app-agenda>
  <app-agenda *ngIf="currentAgenda==7" [agenda]="agenda7"></app-agenda>
  <app-agenda *ngIf="currentAgenda==8" [agenda]="agenda8"></app-agenda>
  <app-agenda *ngIf="currentAgenda==9" [agenda]="agenda9"></app-agenda>
  <app-agenda *ngIf="currentAgenda==10" [agenda]="agenda10"></app-agenda>

</div>
<div class="content" *ngIf="agenda && !app.isAmdin">
  <app-agenda-lock [agenda]="agenda"></app-agenda-lock>
</div>
