<div class="loading" *ngIf="showLoading">
  <div class="loader">
    <span class="dot dot_1"></span>
    <span class="dot dot_2"></span>
    <span class="dot dot_3"></span>
    <span class="dot dot_4"></span>
  </div>
</div>
<div class="content" *ngIf="!showLoading">
  <div class="form-row">
    <div class="form-group col-md-12">
      <span style="float:left;font-size: 30px;color:#3097D1">Devis</span>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="close()" style="float:right">Fermer</button>
    </div>
  </div>
  <hr>
  <form [formGroup]="devisForm" (submit)="submitDevisForm(devisForm.value)">
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="lastname">Titre</label>
        <input type="text" class="form-control" id="title" [formControl]="devisForm.controls['title']">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="lastname">Date</label>
        <input type="date" class="form-control" id="date" [formControl]="devisForm.controls['date']">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Fichier PDF</label>
        <br>
        <label class="btn btn-default">
          <input type="file" (change)="selectFile($event)" accept="application/pdf">
        </label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="remark">Description</label>
        <textarea class="form-control" id="description" rows="10" style="width: 250px;" [froalaEditor]='options'
          [formControl]="devisForm.controls['description']"></textarea>
      </div>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="devisFormError">
      {{devisFormErrorMessage}}
    </div>
    <button type="submit" class="btn btn-success" [disabled]="!devisForm.valid">Enregistrer</button>
  </form>
</div>
