import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Team } from '../models/models';

@Component({
  selector: 'app-navbar',
  templateUrl: 'app-navbar.component.html',
  styles: []
})
export class AppNavbarComponent implements OnInit {
  showNavBar = false;
  mobileMenuOpen = false;
  showEditPassword = false;
  passwordForm: FormGroup = this.formBuilder.group({
    'password': [null, Validators.required]
  });

  constructor(
    private afs: AngularFirestore, public afAuth: AngularFireAuth,
    public app: AppComponent,
    private router: Router, private formBuilder: FormBuilder) {
    this.afAuth.authState.subscribe(data => {
      if (data && data.uid) {
        this.showNavBar = true;
      } else {
        this.showNavBar = false;
      }
    });
  }

  ngOnInit() {
  }

  logout() {
    this.afAuth.signOut().then(function () {
      this.app.isAuthenticate = false;
    });
  }

  submitPasswordForm(value) {
    this.afAuth.currentUser.then(user => {
      this.afs.collection<Team>('Teams', ref => ref.where('email', '==', user.email)).valueChanges().subscribe((data) => {
        if (data[0]) {
          this.afs.doc<Team>('Teams/Team' + data[0].id).update({
            password: value.password
          });
        }
        user.updatePassword(value.password).then(() => {
          this.showEditPassword = false;
        });
      });
    });
    
  }
}
