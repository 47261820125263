export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDL1RptusoareJha-C2Plcxvt2o8htwzcc",
    authDomain: "lamouline-manager.firebaseapp.com",
    databaseURL: "https://lamouline-manager.firebaseio.com",
    projectId: "lamouline-manager",
    storageBucket: "lamouline-manager.appspot.com",
    messagingSenderId: "709891074133"
  }
};
