<div class="loading" *ngIf="showLoader">
  <div class="loader">
    <span class="dot dot_1"></span>
    <span class="dot dot_2"></span>
    <span class="dot dot_3"></span>
    <span class="dot dot_4"></span>
  </div>
</div>
<div class="content" *ngIf="!showLoader">
  <button type="button" class="btn btn-info" (click)="createClient()">
    Ajouter
  </button>
  <div class="form-row" style="margin-top:20px;">
    <div class="form-group col-md-4">
      <select class="form-control" [(ngModel)]="selectedLetter" (ngModelChange)="changeFilter()">
        <option *ngFor="let letter of letters" [ngValue]="letter.id">{{ letter.id }}
        </option>
      </select>
    </div>
    <div class="form-group col-md-8">
      <div class="form-row">
        <div class="form-group col-md-12">
          <form [formGroup]="searchNameForm" (submit)="searchNameFormSubmit(searchNameForm.value)" autocomplete="off">
            <input type="text" class="form-control" id="lastname" placeholder="Nom"
              [formControl]="searchNameForm.controls['lastname']" autocomplete="new-password" autocorrect="off"
              spellcheck="false" />
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-4">
      <div class="form-row">
        <div class="form-group col-md-12">
          <form [formGroup]="searchPhoneNumberForm" (submit)="searchPhoneNumberFormSubmit(searchPhoneNumberForm.value)"
            autocomplete="off">
            <input type="text" class="form-control" id="phoneNumber" placeholder="Téléphone"
              [formControl]="searchPhoneNumberForm.controls['phoneNumber']" autocomplete="new-password"
              autocorrect="off" spellcheck="false" />
          </form>
        </div>
      </div>
    </div>
    <div class="form-group col-md-8">
      <div class="form-row">
        <div class="form-group col-md-12">
          <form [formGroup]="searchAddressForm" (submit)="searchAddressFormSubmit(searchAddressForm.value)"
            autocomplete="off">
            <input type="text" class="form-control" id="address" placeholder="Adresse"
              [formControl]="searchAddressForm.controls['address']" (input)="searchAddressChange($event.target.value)"
              autocomplete="new-password" autocorrect="off" spellcheck="false" />
            <div class="list-group" style="padding-top:10px;">
              <a class="list-group-item list-group-item" style="cursor : pointer" *ngFor="let result of searchResult"
                (click)="openClient(result.userId)">{{ result.address.street }} - {{ result.address.city }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-hover clientTable">
    <thead>
      <tr>
        <th style="width: 40%">Nom</th>
        <th style="width: 40%">Prénom</th>
        <th style="width: 20%">Ville</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let client of clients" (click)="openClient(client.id)" style="cursor:pointer;">
        <td>{{ client.lastname }}</td>
        <td>{{ client.firstname }}</td>
        <td>{{ client.addresses[0].city }}</td>
      </tr>
    </tbody>
  </table>
  <p class="no-result" *ngIf="clients.length == 0">
    Pas de résultat...
  </p>
</div>