<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top" style="background-color: #3097D1 !important;"
  *ngIf="showNavBar">
  <button class="navbar-toggler" type="button" (click)="mobileMenuOpen = !mobileMenuOpen">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse" [ngClass]="!mobileMenuOpen ? 'collapse' : ''">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" (click)="mobileMenuOpen = false" [routerLink]="['/agendas']">Agenda
        </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" (click)="mobileMenuOpen = false" [routerLink]="['/clients']" *ngIf="app.isAmdin">Clients
        </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" (click)="mobileMenuOpen = false" [routerLink]="['/teams']" *ngIf="app.isAmdin">Equipes
        </a>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" (click)="mobileMenuOpen = false;showEditPassword = true">Modifier mot de passe</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" (click)="mobileMenuOpen = false;logout()">Se déconnecter</a>
      </li>
    </ul>
  </div>
</nav>
<div class="modal" tabindex="-1" role="dialog" *ngIf="showEditPassword">
  <div class="modal-dialog" role="document">
    <form [formGroup]="passwordForm" (submit)="submitPasswordForm(passwordForm.value)">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Mofifier le mot de passe</h5>
          <button type="button" class="close" data-dismiss="modal" (click)="showEditPassword = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="text" class="form-control" id="password" aria-describedby="emailHelp"
            placeholder="Nouveau mot de passe" formControlName="password">
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success" [disabled]="!passwordForm.valid">Enregistrer</button>
        </div>
      </div>
    </form>
  </div>
</div>
