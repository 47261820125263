import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Team } from '../models/models';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {
  team1: Team;
  team2: Team;
  team3: Team;
  team4: Team;
  team5: Team;
  team6: Team;
  team7: Team;
  team8: Team;
  team9: Team;
  team10: Team;
  editPassword = 0;
  teamForm: FormGroup = this.formBuilder.group({
    'chiefTeam1': [null, Validators.required],
    'agendaTeam1': [null, Validators.required],
    'chiefTeam2': [null, Validators.required],
    'agendaTeam2': [null, Validators.required],
    'chiefTeam3': [null, Validators.required],
    'agendaTeam3': [null, Validators.required],
    'chiefTeam4': [null, Validators.required],
    'agendaTeam4': [null, Validators.required],
    'chiefTeam5': [null, Validators.required],
    'agendaTeam5': [null, Validators.required],
    'chiefTeam6': [null, Validators.required],
    'agendaTeam6': [null, Validators.required],
    'chiefTeam7': [null, Validators.required],
    'agendaTeam7': [null, Validators.required],
    'chiefTeam8': [null, Validators.required],
    'agendaTeam8': [null, Validators.required],
    'chiefTeam9': [null, Validators.required],
    'agendaTeam9': [null, Validators.required],
    'chiefTeam10': [null, Validators.required],
    'agendaTeam10': [null, Validators.required]
  });

  constructor(private afs: AngularFirestore, public afAuth: AngularFireAuth, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.afs.doc<Team>("/Teams/Team1").valueChanges().subscribe(data => {
      this.team1 = data;
      this.teamForm.controls['chiefTeam1'].setValue(this.team1.chief);
      this.teamForm.controls['agendaTeam1'].setValue(this.team1.agenda);
    });
    this.afs.doc<Team>("/Teams/Team2").valueChanges().subscribe(data => {
      this.team2 = data;
      this.teamForm.controls['chiefTeam2'].setValue(this.team2.chief);
      this.teamForm.controls['agendaTeam2'].setValue(this.team2.agenda);
    });
    this.afs.doc<Team>("/Teams/Team3").valueChanges().subscribe(data => {
      this.team3 = data;
      this.teamForm.controls['chiefTeam3'].setValue(this.team3.chief);
      this.teamForm.controls['agendaTeam3'].setValue(this.team3.agenda);
    });
    this.afs.doc<Team>("/Teams/Team4").valueChanges().subscribe(data => {
      this.team4 = data;
      this.teamForm.controls['chiefTeam4'].setValue(this.team4.chief);
      this.teamForm.controls['agendaTeam4'].setValue(this.team4.agenda);
    });
    this.afs.doc<Team>("/Teams/Team5").valueChanges().subscribe(data => {
      this.team5 = data;
      this.teamForm.controls['chiefTeam5'].setValue(this.team5.chief);
      this.teamForm.controls['agendaTeam5'].setValue(this.team5.agenda);
    });
    this.afs.doc<Team>("/Teams/Team6").valueChanges().subscribe(data => {
      this.team6 = data;
      this.teamForm.controls['chiefTeam6'].setValue(this.team6.chief);
      this.teamForm.controls['agendaTeam6'].setValue(this.team6.agenda);
    });
    this.afs.doc<Team>("/Teams/Team7").valueChanges().subscribe(data => {
      this.team7 = data;
      this.teamForm.controls['chiefTeam7'].setValue(this.team7.chief);
      this.teamForm.controls['agendaTeam7'].setValue(this.team7.agenda);
    });
    this.afs.doc<Team>("/Teams/Team8").valueChanges().subscribe(data => {
      this.team8 = data;
      this.teamForm.controls['chiefTeam8'].setValue(this.team8.chief);
      this.teamForm.controls['agendaTeam8'].setValue(this.team8.agenda);
    });
    this.afs.doc<Team>("/Teams/Team9").valueChanges().subscribe(data => {
      this.team9 = data;
      this.teamForm.controls['chiefTeam9'].setValue(this.team9.chief);
      this.teamForm.controls['agendaTeam9'].setValue(this.team9.agenda);
    });
    this.afs.doc<Team>("/Teams/Team10").valueChanges().subscribe(data => {
      this.team10 = data;
      this.teamForm.controls['chiefTeam10'].setValue(this.team10.chief);
      this.teamForm.controls['agendaTeam10'].setValue(this.team10.agenda);
    });
  }

  submitForm(value) {
    this.afs.doc<Team>('Teams/Team1').update({
      chief: value.chiefTeam1,
      agenda : value.agendaTeam1
    });
    this.afs.doc<Team>('Teams/Team2').update({
      chief: value.chiefTeam2,
      agenda : value.agendaTeam2
    });
    this.afs.doc<Team>('Teams/Team3').update({
      chief: value.chiefTeam3,
      agenda : value.agendaTeam3
    });
    this.afs.doc<Team>('Teams/Team4').update({
      chief: value.chiefTeam4,
      agenda : value.agendaTeam4
    });
    this.afs.doc<Team>('Teams/Team5').update({
      chief: value.chiefTeam5,
      agenda : value.agendaTeam5
    });
    this.afs.doc<Team>('Teams/Team6').update({
      chief: value.chiefTeam6,
      agenda : value.agendaTeam6
    });
    this.afs.doc<Team>('Teams/Team7').update({
      chief: value.chiefTeam7,
      agenda : value.agendaTeam7
    });
    this.afs.doc<Team>('Teams/Team8').update({
      chief: value.chiefTeam8,
      agenda : value.agendaTeam8
    });
    this.afs.doc<Team>('Teams/Team9').update({
      chief: value.chiefTeam9,
      agenda : value.agendaTeam9
    });
    this.afs.doc<Team>('Teams/Team10').update({
      chief: value.chiefTeam10,
      agenda : value.agendaTeam10
    });
  }
}
