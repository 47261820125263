<div class="content">
  <form [formGroup]="teamForm" (submit)="submitForm(teamForm.value)">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" style="width: 10%">#</th>
          <th scope="col" style="width: 30%">Chef d'équipe</th>
          <th scope="col" style="width: 30%">Agenda</th>
          <th scope="col" style="width: 30%">Connexion</th>
          <!-- <th scope="col" style="width: 15%"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="team1">
          <th scope="row">1</th>
          <td><input type="text" class="form-control" id="teamChief1" formControlName="chiefTeam1"></td>
          <td><select class="form-control" id="teamAgenda2" formControlName="agendaTeam1">
              <option [ngValue]="'agenda1'">Agenda 1</option>
              <option [ngValue]="'agenda2'">Agenda 2</option>
              <option [ngValue]="'agenda3'">Agenda 3</option>
              <option [ngValue]="'agenda4'">Agenda 4</option>
              <option [ngValue]="'agenda5'">Agenda 5</option>
              <option [ngValue]="'agenda6'">Agenda 6</option>
              <option [ngValue]="'agenda7'">Agenda 7</option>
              <option [ngValue]="'agenda8'">Agenda 8</option>
              <option [ngValue]="'agenda9'">Agenda 9</option>
              <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team1.email}} <br> {{team1.password}}</td>
        </tr>
        <tr *ngIf="team2">
          <th scope="row">2</th>
          <td><input type="text" class="form-control" id="teamChief2" formControlName="chiefTeam2"></td>
          <td><select class="form-control" id="teamAgenda2" formControlName="agendaTeam2">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team2.email}} <br> {{team2.password}}</td>
        </tr>
        <tr *ngIf="team3">
          <th scope="row">3</th>
          <td><input type="text" class="form-control" id="teamChief3" formControlName="chiefTeam3"></td>
          <td><select class="form-control" id="teamAgenda3" formControlName="agendaTeam3">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team3.email}} <br> {{team3.password}}</td>
        </tr>
        <tr *ngIf="team4">
          <th scope="row">4</th>
          <td><input type="text" class="form-control" id="teamChief4" formControlName="chiefTeam4"></td>
          <td><select class="form-control" id="teamAgenda4" formControlName="agendaTeam4">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team4.email}} <br> {{team4.password}}</td>
        </tr>
        <tr *ngIf="team5">
          <th scope="row">5</th>
          <td><input type="text" class="form-control" id="teamChief5" formControlName="chiefTeam5"></td>
          <td><select class="form-control" id="teamAgenda5" formControlName="agendaTeam5">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team5.email}} <br> {{team5.password}}</td>
        </tr>
        <tr *ngIf="team6">
          <th scope="row">6</th>
          <td><input type="text" class="form-control" id="teamChief6" formControlName="chiefTeam6"></td>
          <td><select class="form-control" id="teamAgenda6" formControlName="agendaTeam6">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team6.email}} <br> {{team6.password}}</td>
        </tr>
        <tr *ngIf="team7">
          <th scope="row">7</th>
          <td><input type="text" class="form-control" id="teamChief7" formControlName="chiefTeam7"></td>
          <td><select class="form-control" id="teamAgenda7" formControlName="agendaTeam7">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team7.email}} <br> {{team7.password}}</td>
        </tr>
        <tr *ngIf="team8">
          <th scope="row">8</th>
          <td><input type="text" class="form-control" id="teamChief8" formControlName="chiefTeam8"></td>
          <td><select class="form-control" id="teamAgenda8" formControlName="agendaTeam8">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team8.email}} <br> {{team8.password}}</td>
        </tr>
        <tr *ngIf="team9">
          <th scope="row">9</th>
          <td><input type="text" class="form-control" id="teamChief9" formControlName="chiefTeam9"></td>
          <td><select class="form-control" id="teamAgenda9" formControlName="agendaTeam9">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team9.email}} <br> {{team9.password}}</td>
        </tr>
        <tr *ngIf="team10">
          <th scope="row">10</th>
          <td><input type="text" class="form-control" id="teamChief10" formControlName="chiefTeam10"></td>
          <td><select class="form-control" id="teamAgenda10" formControlName="agendaTeam10">
            <option [ngValue]="'agenda1'">Agenda 1</option>
            <option [ngValue]="'agenda2'">Agenda 2</option>
            <option [ngValue]="'agenda3'">Agenda 3</option>
            <option [ngValue]="'agenda4'">Agenda 4</option>
            <option [ngValue]="'agenda5'">Agenda 5</option>
            <option [ngValue]="'agenda6'">Agenda 6</option>
            <option [ngValue]="'agenda7'">Agenda 7</option>
            <option [ngValue]="'agenda8'">Agenda 8</option>
            <option [ngValue]="'agenda9'">Agenda 9</option>
            <option [ngValue]="'agenda10'">Agenda 10</option>
            </select></td>
          <td>{{team10.email}} <br> {{team10.password}}</td>
        </tr>
      </tbody>
    </table>
    <button type="submit" class="btn btn-success"
      [disabled]="!teamForm.valid">Enregistrer</button>
  </form>
</div>
