import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Client, SearchAddress } from '../models/models';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  showLoader = false;
  letters = [
    { id: 'A' },
    { id: 'B' },
    { id: 'C' },
    { id: 'D' },
    { id: 'E' },
    { id: 'F' },
    { id: 'G' },
    { id: 'H' },
    { id: 'I' },
    { id: 'J' },
    { id: 'K' },
    { id: 'L' },
    { id: 'M' },
    { id: 'N' },
    { id: 'O' },
    { id: 'P' },
    { id: 'Q' },
    { id: 'R' },
    { id: 'S' },
    { id: 'T' },
    { id: 'U' },
    { id: 'V' },
    { id: 'W' },
    { id: 'X' },
    { id: 'Y' },
    { id: 'Z' }]
  searchForm: FormGroup;
  selectedLetter = 'A';
  selectedLastname = '';
  selectedPhoneNumber = '';
  selectedAddress = '';
  clients: Client[] = [];
  adresses : SearchAddress[] = [];
  searchResult: SearchAddress[] = [];
  searchNameForm: FormGroup = this.formBuilder.group(
    {
      lastname: [null]
    }
  );
  searchPhoneNumberForm: FormGroup = this.formBuilder.group(
    {
      phoneNumber: [null]
    }
  );
  searchAddressForm: FormGroup = this.formBuilder.group(
    {
      address: [null]
    }
  );

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore
  ) {

  }

  ngOnInit() {
    this.showLoader = true;
    var criteria = window.localStorage.getItem('criteria');
    if(!criteria){
      this.afs.collection<Client>('Clients', ref => ref.orderBy('lastname').startAt(this.selectedLetter).endAt(this.nextLetter(this.selectedLetter))).valueChanges().subscribe(data => {
        this.showLoader = false;
        this.clients = data;
      });
    }else{
      var parsedCriteria = JSON.parse(criteria);
      if(parsedCriteria.selectedLetter){
        this.selectedLetter = parsedCriteria.selectedLetter;
        this.afs.collection<Client>('Clients', ref => ref.orderBy('lastname').startAt(this.selectedLetter).endAt(this.nextLetter(this.selectedLetter))).valueChanges().subscribe(data => {
          this.showLoader = false;
          this.clients = data;
        });;
      }
      if(parsedCriteria.selectedLastname){
        this.searchNameForm.controls['lastname'].setValue(parsedCriteria.selectedLastname);
        this.afs.collection<Client>('Clients', ref => ref.where('lastname', '==', parsedCriteria.selectedLastname)).valueChanges().subscribe(data => {
          this.showLoader = false;
          this.clients = data;
        });;
      }
      if(parsedCriteria.selectedPhoneNumber){
        this.searchPhoneNumberForm.controls['phoneNumber'].setValue(parsedCriteria.selectedPhoneNumber);
        this.afs.collection<Client>('Clients', ref => ref.where('mobileNumber', '==', parsedCriteria.selectedPhoneNumber)).valueChanges().subscribe(data => {
          this.showLoader = false;
          this.clients = data;
        });;
      }
      window.localStorage.removeItem('criteria');
    }
    this.afs.collection<Client>('Clients').valueChanges().subscribe(clients => {
      clients.forEach(client => {
        client.addresses.forEach(address => {
          var searchAddress : SearchAddress = {
            userId : client.id,
            address : address
          }
          this.adresses.push(searchAddress);
        });
      });
    });
  }

  searchAddressChange(value){
    if (value) {
      this.searchResult = this.adresses.filter(x => (x.address.street.trim()).startsWith(value)).slice(0, 5);
    } else {
      this.searchResult = [];
    }
  }

  changeFilter() {
    this.showLoader = true;
    this.searchNameForm.controls['lastname'].setValue('');
    this.searchPhoneNumberForm.controls['phoneNumber'].setValue('');
    this.searchAddressForm.controls['address'].setValue('');
    this.selectedLastname = '';
    this.selectedPhoneNumber = '';
    this.selectedAddress = '';
    var nextLetter = this.nextLetter(this.selectedLetter);
    if (this.selectedLetter == 'Z') {
      nextLetter = 'Z';
    }
    this.afs.collection<Client>('Clients', ref => ref.orderBy('lastname').startAt(this.selectedLetter).endAt(nextLetter)).valueChanges().subscribe(data => {
      this.showLoader = false;
      this.clients = data;
    });
  }

  searchNameFormSubmit(value: any) {
    this.showLoader = true;
    this.selectedLetter = '';
    this.selectedLastname = value.lastname;
    this.selectedPhoneNumber = '';
    this.selectedAddress = '';
    this.searchPhoneNumberForm.controls['phoneNumber'].setValue('');
    this.searchAddressForm.controls['address'].setValue('');
    this.afs.collection<Client>('Clients', ref => ref.where('lastname', '==', value.lastname)).valueChanges().subscribe(data => {
      this.showLoader = false;
      this.clients = data;
    });;
  }

  searchPhoneNumberFormSubmit(value: any) {
    this.showLoader = true;
    this.selectedLetter = '';
    this.selectedLastname = '';
    this.selectedPhoneNumber = value.phoneNumber;
    this.selectedAddress = '';
    this.searchNameForm.controls['lastname'].setValue('');
    this.searchAddressForm.controls['address'].setValue('');
    this.afs.collection<Client>('Clients', ref => ref.where('mobileNumber', '==', value.phoneNumber)).valueChanges().subscribe(data => {
      this.showLoader = false;
      this.clients = data;
    });;
  }

  searchAddressFormSubmit(value: any) {
    this.selectedLetter = '';
    this.selectedLastname = '';
    this.selectedPhoneNumber = '';
    this.selectedAddress = value.address;
    this.searchNameForm.controls['lastname'].setValue('');
    this.searchPhoneNumberForm.controls['phoneNumber'].setValue('');
  }

  createClient(): void {
    this.router.navigateByUrl('/newClient');
  }

  openClient(clientId): void {
    var criteria = {
      selectedLetter : this.selectedLetter,
      selectedLastname: this.selectedLastname,
      selectedPhoneNumber: this.selectedPhoneNumber,
      selectedAddress: this.selectedAddress
    }
    window.localStorage.setItem('criteria', JSON.stringify(criteria));
    this.router.navigateByUrl('/client/' + clientId);
  }

  nextLetter(letter) {
    return letter.replace(/([A-Z])[^A-Z]*$/, function (a) {
      var c = a.charCodeAt(0);
      switch (c) {
        case 90: return 'A';
        default: return String.fromCharCode(++c);
      }
    });
  }
}

